export const SET_CURRENT_CLIENT_ID = 'SET_CURRENT_CLIENT_ID';
export const SET_CURRENT_EVENT_ID = 'SET_CURRENT_EVENT_ID';

export const setCurrentClientID = clientID => ({
	type: SET_CURRENT_CLIENT_ID,
	clientID,
});
export const setCurrentEventID = eventID => ({
	type: SET_CURRENT_EVENT_ID,
	eventID,
});
