import React from 'react';
import PreferenceToggle from './NotificationPreferenceToggle';

const NotificationPreferencesSelection = ({ setting, handleChange }) => {
	const methods = {
		'app': 'In App',
		'push': 'Push',
	};
	const descriptions = {
		'Likes': {
			title: 'Social Feed Likes',
			description: 'When someone likes your post',
		},

		'Comments': {
			title: 'Social Feed Comments',
			description: 'When someone comments on your post',
		},

		'Kudos': {
			title: 'Receiving Kudos',
			description: 'When someone gives you a kudo',
		},
	};

	const { title, description } = descriptions[setting.type];

	return (
		<div className="notificationSetting">
			<h3>{title}</h3>
			<small>{description}</small>
			<div className="notificationSelectionContainer">
				{Object.keys(methods).map(method => (
					<div key={method} className="notificationSelection">
						<h4>{methods[method]}</h4>
						<PreferenceToggle preference={setting[method]} handleChange={handleChange} />
					</div>
				))}
			</div>
		</div>
	);
};

export default NotificationPreferencesSelection;
