import { NotificationsServices } from '../../services';

export const SET_UNREAD_COUNTS = 'SET_UNREAD_COUNTS';
export const INCREASE_UNREAD_COUNT = 'INCREASE_UNREAD_COUNT';

export const increaseUnreadCount = announcementOrNotification => ({
	type: INCREASE_UNREAD_COUNT,
	announcementOrNotification,
});

export const setUnreadCounts = (unreadCounts, from) => ({
	type: SET_UNREAD_COUNTS,
	unreadCounts,
	from,
});

export const thunkFetchUnreadCounts = (attendeeID, eventID, from) => async dispatch => {
	try {
		const { data } = await NotificationsServices.getUnreadCounts(attendeeID, eventID);
		const unreadCounts = data.unreadNotificationsAndAnnouncementsCounts;
		dispatch(setUnreadCounts(unreadCounts, from));
	} catch (error) {
		console.log('thunkFetchUnreadCount', error);
	}
};
