import GraphQLController from './GraphQL';
import { Constants } from '../common';
import AgendaGQL from './gql/AgendaGQL';

export default {
	agenda: async event_id => {
		let variables = { event_id, client: Constants.CLIENT_ID };
		let mutation = AgendaGQL.Agenda;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	groupAgenda: async (event_id, group_id) => {
		let variables = { event_id, track_id: group_id };
		let mutation = AgendaGQL.Agenda;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
};
