import { gql } from 'graphql-tag';

export default {
	Events: gql`
		query me {
			me {
				id
				email
				events {
					id
					name
					status
					start_time
					end_time
					event_image_url
					location
					theme
					single_page_app
					anonymous_login
					homescreen_layout
					active_homescreen
					homescreen_theme
					homescreen_widget_order
					client_id
					analytics_id
					attendee_metadata {
						metadata
					}
					groups {
						id
						name
						track
						event_id
					}
					updated_at
				}
				push_token
				last_sync
			}
		}
	`,

	Event: gql`
		query event($id: Uint) {
			event(id: $id) {
				id
				client_id
				name
				homescreen_layout
				custom_metadata_fields
				custom_metadata_attributes {
					key
					name
					category
				}
				analytics_id
				side_navigation
				theme
				start_time
				end_time
				status
				single_page_app
				anonymous_login
				active_homescreen
				homescreen_theme
				homescreen_widget_order
				pages {
					id
					name
					body
				}
				attendee_metadata {
					metadata
				}
				groups {
					id
					name
					track
					event_id
				}
				event_kudo {
					id
					attendee_kudo_title
				}
				event_checkin {
					id
					active
					settings
				}
				event_connection {
					id
					active
					settings
				}
			}
		}
	`,
	EventPoints: gql`
		query eventPoint($event_id: Uint) {
			eventPoint(event_id: $event_id) {
				active
				src
				image_url
			}
		}
	`,
};
