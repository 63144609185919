import DomainServices from '../services/DomainServices';

const types = {
	DOMAIN: 'DOMAIN',
	DOMAIN_BRANDING: 'DOMAIN_BRANDING',
};

export const domainActions = {
	domain: url => async dispatch => {
		let { error, data } = await DomainServices.Domain(url);
		if (data) {
			dispatch({ type: types.DOMAIN, data: { domain: data.domainByURL } });
			return data;
		} else {
			return error;
		}
	},
	setDomainBranding: domainBranding => async dispatch => {
		dispatch({
			type: types.DOMAIN_BRANDING,
			data: {
				domainBranding: domainBranding,
			},
		});
	},
};

const initialState = {
	domain: [],
	domainBranding: {},
};

export const reducer = (state = initialState, action) => {
	const { type, data } = action;
	switch (type) {
		case types.DOMAIN:
			const { domainByURL } = data;
			return { ...state, domain: domainByURL };
		case types.DOMAIN_BRANDING:
			const { domainBranding } = data;
			return { ...state, domainBranding: domainBranding };
		default:
			return state;
	}
};
