import React from 'react';
import { Switch } from 'antd';

import './NotificationPreferenceToggle.scss';

const PreferenceToggle = props => {
	const { preference, handleChange } = props;
	const { subscribed } = preference;
	return (
		<div className="NotificationPreferenceToggle">
			<Switch checked={subscribed} onChange={() => handleChange(preference)} />
		</div>
	);
};

export default PreferenceToggle;
