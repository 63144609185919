// import Api from './ApiController';
import apolloClient from './ApolloClient';

export default class graphQL {
	static Query = async (query, variables = null) => {
		try {
			let { loading, error, data } = await apolloClient.query({
				query,
				variables,
				fetchPolicy: 'network-only',
			});
			return { loading, error, data };
		} catch (error) {
			return this.handleErrors(error);
		}
	};

	static Mutation = async (mutation, variables = null) => {
		try {
			let result = await apolloClient.mutate({
				mutation,
				variables,
			});
			return result;
		} catch (error) {
			// console.log('error in mutation', error);
			// mutation.definitions.forEach(def => {
			// 	console.log(def.name);
			// });
			return this.handleErrors(error);
		}
	};
	static handleErrors = ({ graphQLErrors, networkError }) => {
		console.log('any error? ', graphQLErrors, 'networkError', networkError);
		if (graphQLErrors && graphQLErrors.length) {
			const error =
				typeof graphQLErrors == 'string' ? JSON.parse(graphQLErrors) : graphQLErrors[0].message;
			return { error };
		}
		// if (networkError && networkError.message) alert(networkError.message);
		// if (networkError && networkError.message) alert("Internet is not available.")
		if (networkError && networkError.statusCode === 401) {
			console.log('401 error - logging out in two seconds');
			console.log(
				'This 401 is likely either because we rotated the JWT_SECRET or your jwt token has expired.',
			);
			setTimeout(() => {
				if (!window.location.href.includes('/logout')) {
					console.log('401 -> redirecting to /logout');
					window.location.href = `${window.location.origin}/logout`;
				}
			}, 2000);
		}
	};
}
