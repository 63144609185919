import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import {
	addGroups,
	removeGroups,
	addAnnouncement,
	updateAnnouncement,
	increaseUnreadCount,
	deleteAnnouncement,
	addAgendaItem,
	removeAgendaItem,
	updateAgendaItem,
	addMaterials,
	removeMaterial,
	updateMaterial,
	addMaterialGroups,
	removeMaterialGroups,
} from 'redux/actions';
// TO DO: Separate listeners into different files

const GroupsSubscriptions = props => {
	const {
		socket,
		eventID,
		userGroups,
		addGroups,
		removeGroups,
		addAnnouncement,
		increaseUnreadCount,
		updateAnnouncement,
		deleteAnnouncement,
		addAgendaItem,
		removeAgendaItem,
		updateAgendaItem,
		addMaterials,
		removeMaterial,
		updateMaterial,
		addMaterialGroups,
		removeMaterialGroups,
	} = props;
	const propsToSend = { ...props };
	delete propsToSend.children;

	const groupIDs =
		userGroups &&
		userGroups.reduce((rooms, group) => {
			if (group.event_id === eventID) rooms.push(`app:groups:${group.id}`);
			return rooms;
		}, []);

	const WS_GROUPS_BY_HTTP = {
		// Groups
		ADD_GROUPS_TO_ATTENDEE: 'attendee/:attendeeID/addGroups/PATCH',
		REMOVE_GROUPS_FROM_ATTENDEE: 'attendee/:attendeeID/removeGroups/PATCH',
		ADD_ATTENDEE_TO_GROUP: 'groups/:groupID/addAttendees/PATCH',
		REMOVE_ATTENDEE_FROM_GROUP: 'groups/:groupID/removeAttendees/PATCH',

		// Announcements
		ON_DEMAND_ANNOUNCEMENT: 'announcements/onDemand/POST',
		ADDED_ANNOUNCEMENT: 'announcements/addedAnnouncementGroup/POST',
		DELETE_ANNOUNCEMENT: 'announcements/:announcementID/DELETE',
		UPDATE_ANNOUNCEMENT: 'announcements/:announcementID/PATCH',
		SCHEDULED_ANNOUNCEMENT: 'announcements/scheduledAnnouncement/POST',

		// Agenda
		ADD_AGENDA_ITEM: 'agenda/POST',
		UPDATE_AGENDA_ITEM: 'agenda/:agendaItemID/PATCH',
		REMOVE_AGENDA_ITEM: 'agenda/:agendaItemID/DELETE',

		// Material
		ADD_MATERIAL: 'agenda/:agendaItemID/addMaterial/PATCH',
		UPDATE_MATERIAL: 'agenda/:agendaItemID/updateMaterial/PATCH',
		REMOVE_MATERIAL: 'agenda/:agendaItemID/deleteMaterial/DELETE',
		ADD_GROUPS_TO_MATERIAL: 'agenda/:agendaItemID/addMaterialGroups/PATCH',
		REMOVE_GROUPS_FROM_MATERIAL: 'agenda/:agendaItemID/removeMaterialGroups/PATCH',
	};

	// DELETE ANNOUNCEMENT IN GROUP SUBSCRIPTIONS -> create action
	const subscribe = () => {
		console.log('⚡️ GroupSubscriptions Mounted ⚡️', groupIDs);
		socket.emit(`app/attendee/groups/:groupIDs/SUBSCRIBE`, { groupIDs });
	};

	const unsubscribe = prevGroupIDs => {
		console.log(`🚶‍♂️ GroupSubscriptions Unmounted 🚶‍♂️`, prevGroupIDs);
		socket.emit(`app/attendee/groups/:groupIDs/UNSUBSCRIBE`, { groupIDs: prevGroupIDs });
	};

	//#region Managing what groups attendees are listening to
	const listenForAddedAttendeeGroups = () => {
		socket.on(WS_GROUPS_BY_HTTP.ADD_GROUPS_TO_ATTENDEE, payload => {
			const { data, error } = payload;
			if (error) console.log('Error adding group', error);
			else {
				addGroups(data, 'listenForAddedAttendeeGroup');
				console.log('Group added to attendee!', data);

				const { adminEventID } = payload;
				console.log('Admin Event ID: ', adminEventID, 'App Event: ', eventID);

				if (Number(adminEventID) === eventID) {
					const newGroupIDs = data.map(g => `app:groups:${g.id}`);
					groupIDs.push(...newGroupIDs);
					socket.emit(`app/attendee/groups/:groupIDs/SUBSCRIBE`, { groupIDs: newGroupIDs });
				}
			}
		});
	};

	const listenForRemovedAttendeeGroups = () => {
		socket.on(WS_GROUPS_BY_HTTP.REMOVE_GROUPS_FROM_ATTENDEE, payload => {
			const { data, error } = payload;

			if (error) console.log('Error removing group', eventID, error);
			else {
				removeGroups(data, 'listenForRemovedAttendeeGroups');
				console.log('Group removed from an attendee', data);

				const { adminEventID } = payload;
				console.log('Admin Event ID: ', adminEventID, 'App Event: ', eventID);

				if (Number(adminEventID) === eventID) {
					const groupIDs = data.map(g => `app:groups:${g.id}`);
					socket.emit(`app/attendee/groups/:groupIDs/UNSUBSCRIBE`, { groupIDs });
				}
			}
		});
	};

	//Many attendees added/removed to a single group
	const listenForAddedGroupAttendee = () => {
		socket.on(WS_GROUPS_BY_HTTP.ADD_ATTENDEE_TO_GROUP, payload => {
			const { error } = payload;
			if (error) console.log('Error adding attendee', error);
			else {
				const { group, adminEventID } = payload;
				delete group.attendees;
				addGroups([{ ...group }], 'listenForAddedGroupAttendee');
				console.log('Attendee added to a group', group);
				console.log('Admin Event ID: ', adminEventID, 'App Event: ', eventID);
				if (adminEventID === eventID) {
					const newGroupID = [`app:groups:${group.id}`];
					groupIDs.push(...newGroupID);
					socket.emit(`app/attendee/groups/:groupIDs/SUBSCRIBE`, { groupIDs: newGroupID });
				}
			}
		});
	};

	const listenForRemovedGroupAttendee = () => {
		socket.on(WS_GROUPS_BY_HTTP.REMOVE_ATTENDEE_FROM_GROUP, payload => {
			const { error } = payload;
			if (error) console.log('Error removing attendee', error);
			else {
				const { groupID, adminEventID } = payload;
				removeGroups([{ id: groupID }], 'listenForRemovedGroupAttendee');
				console.log('Attendee removed from a group', groupID);
				console.log('Admin Event ID: ', adminEventID, 'App Event: ', eventID);
				if (adminEventID === eventID) {
					const oldGroupID = [`app:groups:${groupID}`];
					socket.emit(`app/attendee/groups/:groupIDs/UNSUBSCRIBE`, { groupIDs: oldGroupID });
				}
			}
		});
	};
	//#endregion

	//#region Announcement listeners
	const listenForOnDemandAnnouncement = () => {
		socket.on(WS_GROUPS_BY_HTTP.ON_DEMAND_ANNOUNCEMENT, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with on demand announcement', error);
			else {
				console.log('On Demand Announcement sent', data);
				addAnnouncement(data);
				increaseUnreadCount('announcement');
			}
		});
	};

	const listenForScheduledAnnouncement = () => {
		socket.on(WS_GROUPS_BY_HTTP.SCHEDULED_ANNOUNCEMENT, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with scheduled announcement', error);
			else {
				console.log('Scheduled Announcement received', data);
				addAnnouncement(data);
				increaseUnreadCount('announcement');
			}
		});
	};

	const listenForUpdatedAnnouncement = () => {
		socket.on(WS_GROUPS_BY_HTTP.UPDATE_ANNOUNCEMENT, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating announcement', error);
			else {
				delete data.groups;
				updateAnnouncement(data);
				console.log('Updating announcement', data);
			}
		});
	};

	const listenForDeletedAnnouncement = () => {
		socket.on(WS_GROUPS_BY_HTTP.DELETE_ANNOUNCEMENT, payload => {
			const { data, error } = payload;
			if (error) console.log('Error deleting announcement', error);
			else {
				const { id } = data;
				deleteAnnouncement(id);
				console.log('Deleting announcement', data);
			}
		});
	};

	// //attendeeds/groups added to a announcement
	const listenForNewAnnouncementForAttendee = () => {
		socket.on(WS_GROUPS_BY_HTTP.ADDED_ANNOUNCEMENT, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with adding a new announcement', error);
			else {
				console.log('Error with announcement', data);
				addAnnouncement(data);
				increaseUnreadCount('announcement');
			}
		});
	};

	//#endregion

	//#region Agenda listeners
	const listenForAddedAgendaItem = () => {
		socket.on(WS_GROUPS_BY_HTTP.ADD_AGENDA_ITEM, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with adding agenda item', error);
			else {
				console.log('Agenda item added', data);
				delete data.groups;
				addAgendaItem(data);
			}
		});
	};
	const listenForRemovedAgendaItem = () => {
		socket.on(WS_GROUPS_BY_HTTP.REMOVE_AGENDA_ITEM, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with removing agenda item', error);
			else {
				console.log('Agenda item removed', data);
				removeAgendaItem(data.id);
			}
		});
	};
	const listenForUpdatedAgendaItem = () => {
		socket.on(WS_GROUPS_BY_HTTP.UPDATE_AGENDA_ITEM, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with updating agenda item', error);
			else {
				console.log('Agenda item updated', data);
				updateAgendaItem(data);
			}
		});
	};

	//#endregion

	//#region Material listeners
	const listenForAddedMaterial = () => {
		socket.on(WS_GROUPS_BY_HTTP.ADD_MATERIAL, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with adding material', error);
			else {
				const { agendaItemID } = payload;
				addMaterials(data, agendaItemID);
				console.log('Material added', data);
			}
		});
	};
	const listenForUpdatedMaterial = () => {
		socket.on(WS_GROUPS_BY_HTTP.UPDATE_MATERIAL, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with updating material', error);
			else {
				const { agendaItemID } = payload;
				updateMaterial(data, agendaItemID);
				console.log('Material updated', data);
			}
		});
	};
	const listenForRemovedMaterial = () => {
		socket.on(WS_GROUPS_BY_HTTP.REMOVE_MATERIAL, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with removing material', error);
			else {
				const { agendaItemID } = payload;
				removeMaterial(data.id, agendaItemID);
				console.log('Material removed', data);
			}
		});
	};

	const listenForAddedMaterialGroups = () => {
		socket.on(WS_GROUPS_BY_HTTP.ADD_GROUPS_TO_MATERIAL, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with adding groups to material', error);
			else {
				const { agendaItemID } = payload;
				const { addManyGroupsToMaterial: groupIDs, materialID } = data;
				addMaterialGroups(groupIDs, materialID, agendaItemID);
				console.log('Groups added to material', data, agendaItemID);
			}
		});
	};
	const listenForRemovedMaterialGroups = () => {
		socket.on(WS_GROUPS_BY_HTTP.REMOVE_GROUPS_FROM_MATERIAL, payload => {
			const { data, error } = payload;
			if (error) console.log('Error with removing material groups', error);
			else {
				const { agendaItemID } = payload;
				const { removeManyGroupsFromMaterial: groupIDs, materialID } = data;
				removeMaterialGroups(groupIDs, materialID, agendaItemID);
				console.log('Groups removed from material', data, agendaItemID);
			}
		});
	};
	//#endregion

	const listeners = () => [
		listenForAddedGroupAttendee,
		listenForRemovedGroupAttendee,
		listenForRemovedAttendeeGroups,
		listenForAddedAttendeeGroups,

		listenForOnDemandAnnouncement,
		listenForUpdatedAnnouncement,
		listenForDeletedAnnouncement,
		listenForNewAnnouncementForAttendee,
		listenForScheduledAnnouncement,

		listenForAddedAgendaItem,
		listenForRemovedAgendaItem,
		listenForUpdatedAgendaItem,

		listenForAddedMaterial,
		listenForUpdatedMaterial,
		listenForRemovedMaterial,
		listenForAddedMaterialGroups,
		listenForRemovedMaterialGroups,
	];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				groupIDsRoom={groupIDs}
				sub={subscribe}
				unsub={unsubscribe}
				listeners={listeners()}
				routes={Object.values(WS_GROUPS_BY_HTTP)}
				from={'groups'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = ({ ws, context }) => ({
	socket: ws.socket,
	eventID: context.currentEventID,
});

const mdp = dispatch => ({
	addGroups: (groupIDs, from) => dispatch(addGroups(groupIDs, from)),
	removeGroups: (groupIDs, from) => dispatch(removeGroups(groupIDs, from)),
	addAnnouncement: (announcement, from) => dispatch(addAnnouncement(announcement, from)),
	increaseUnreadCount: type => dispatch(increaseUnreadCount(type)),
	updateAnnouncement: announcement => dispatch(updateAnnouncement(announcement)),
	deleteAnnouncement: announcementID => dispatch(deleteAnnouncement(announcementID)),
	addAgendaItem: agendaItem => dispatch(addAgendaItem(agendaItem)),
	removeAgendaItem: agendaItemID => dispatch(removeAgendaItem(agendaItemID)),
	updateAgendaItem: agendaItem => dispatch(updateAgendaItem(agendaItem)),
	addMaterials: (materials, agendaItemID) => dispatch(addMaterials(materials, agendaItemID)),
	removeMaterial: (materialID, agendaItemID) => dispatch(removeMaterial(materialID, agendaItemID)),
	updateMaterial: (material, agendaItemID) => dispatch(updateMaterial(material, agendaItemID)),
	addMaterialGroups: (groupIDs, materialID, agendaItemID) =>
		dispatch(addMaterialGroups(groupIDs, materialID, agendaItemID)),
	removeMaterialGroups: (groupIDs, materialID, agendaItemID) =>
		dispatch(removeMaterialGroups(groupIDs, materialID, agendaItemID)),
});

export default connect(msp, mdp)(GroupsSubscriptions);
