import GraphQLController from './GraphQL';
import NotificationGQL from './gql/NotificationsGQL';

export default {
	attendeeNotifications: async (attendee_id, event_id) => {
		let variables = {
			event_id,
			attendee_id,
		};
		let mutation = NotificationGQL.AttendeeNotifications;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	getUnreadCounts: async (attendee_id, event_id) => {
		let variables = {
			attendee_id,
			event_id,
		};
		let mutation = NotificationGQL.UnreadNotificationsAndAnnouncementsCounts;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},

	setAllNotificationsAsRead: async (attendee_id, event_id) => {
		let variables = {
			attendee_id,
			event_id,
		};
		let mutation = NotificationGQL.SetAllNotificationsAsRead;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
};
