import { gql } from 'graphql-tag';

export default {
	Widgets: gql`
		query eventWidgets($event_id: Uint) {
			eventWidgets(event_id: $event_id) {
				id
				type
				props
				created_at
				updated_at
			}
		}
	`,
	Widget: gql`
		query widget($id: Uint) {
			widget(id: $id) {
				id
				type
				props
				created_at
				updated_at
			}
		}
	`,
};
