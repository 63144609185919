import React from 'react';
import { Table } from 'antd';
import PreferenceToggle from './NotificationPreferenceToggle';

const NotificationPreferences = ({ handleChange, formatData, notificationPreferences }) => {
	const { eventPreferences, notificationTypes, attendeePreferences } = notificationPreferences;
	const data = formatData(notificationTypes, eventPreferences, attendeePreferences);

	const columns = () => {
		return [
			{
				title: '',
				dataIndex: 'type',
				key: 'type',
			},
			{
				title: 'Push',
				dataIndex: 'push',
				key: 'push',
				render: preference => {
					return <PreferenceToggle preference={preference} handleChange={handleChange} />;
				},
			},
			{
				title: 'In App',
				dataIndex: 'app',
				key: 'app',
				render: preference => {
					return <PreferenceToggle preference={preference} handleChange={handleChange} />;
				},
			},
		];
	};

	return (
		<div className="notificationPreferencesWebView">
			<Table
				columns={columns()}
				dataSource={Object.values(data)}
				rowKey="type"
				pagination={false}
			/>
		</div>
	);
};

export default NotificationPreferences;
