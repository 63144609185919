import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressiveAvatar from 'components/ProgressiveAvatar';

import './UserInfo.scss';

const UserInfo = props => {
	const { attendee } = props;
	const navigate = useNavigate();

	const visitProfile = () => {
		navigate(`/directory/profile/${attendee.id}`);
	};
	return (
		<div className="UserInfo" onClick={visitProfile}>
			<ProgressiveAvatar size={40} attendee={attendee} />
			<div className="nameAndProfile">
				<div className="name">
					{attendee.first_name} {attendee.last_name}
				</div>
				<div className="visitProfile">View Profile</div>
			</div>
		</div>
	);
};

export default UserInfo;
