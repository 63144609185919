import React from 'react';
import NotificationPreferencesSelection from './NotificationPreferencesSelection';

const NotificationPreferencesMobile = ({ handleChange, formatData, notificationPreferences }) => {
	const { eventPreferences, notificationTypes, attendeePreferences } = notificationPreferences;
	const data = formatData(notificationTypes, eventPreferences, attendeePreferences);
	return (
		<div className={'notificationPreferencesMobile'}>
			{Object.values(data).map(setting => (
				<NotificationPreferencesSelection
					key={setting.type}
					setting={setting}
					handleChange={handleChange}
				/>
			))}
		</div>
	);
};

export default NotificationPreferencesMobile;
