import { gql } from 'graphql-tag';

export default {
	Directories: gql`
		query eventAttendeesDirectory(
			$event_id: Uint
			$search_term: String
			$offset: Uint
			$items_per_page: Uint
		) {
			eventAttendeesDirectory(
				event_id: $event_id
				search_term: $search_term
				offset: $offset
				items_per_page: $items_per_page
			) {
				nodes {
					id
					email
					avatar_url
					first_name
					last_name
					title
					team
					has_chat_user
				}
				page_info {
					total_count
					has_next_page
				}
			}
		}
	`,
	Attendee: gql`
		query attendee($id: Uint) {
			attendee(id: $id) {
				id
				email
				first_name
				last_name
				company
				title
				team
				bio
				address
				city
				county
				country
				state
				postal_code
				avatar_url
				mobile_number
				phone_number
				facebook_url
				linkedin_url
				website_url
				twitter_url
				blog_url
				has_chat_user
				total_points
			}
		}
	`,
	EventAttendee: gql`
		query eventAttendee($id: Uint, $event_id: Uint) {
			eventAttendee(id: $id, event_id: $event_id) {
				id
				email
				first_name
				last_name
				company
				title
				team
				bio
				address
				city
				county
				country
				state
				avatar_url
				mobile_number
				phone_number
				facebook_url
				linkedin_url
				website_url
				twitter_url
				blog_url
				has_chat_user
				total_points
			}
		}
	`,
	EventAttendeeMetadata: gql`
		query eventAttendeeMetadata($id: Uint, $event_id: Uint) {
			eventAttendeeMetadata(id: $id, event_id: $event_id) {
				metadata
			}
		}
	`,
	EventAttendeePoint: gql`
		query attendeePoints($attendee_id: Uint, $event_id: Uint) {
			attendeePoints(attendee_id: $attendee_id, event_id: $event_id) {
				value
				type
				reason
				type_id
				created_at
			}
		}
	`,
};
