import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import {
	updateLibrary,
	addMedia,
	updateMedia,
	removeMedia,
	updateChildLibrary,
	deleteChildLibrary,
	addChildLibrary,
	addGroupsToLibrary,
	removeGroupsFromLibrary,
} from 'redux/actions';

const WS_LIBRARY_BY_HTTP = {
	UPDATE_LIBRARY: 'library/:libraryID/PATCH',
	ADD_MEDIA: 'media/:eventID/POST',
	UPDATE_MEDIA: 'media/:mediaID/PATCH',
	REMOVE_MEDIA: 'media/:mediaID/DELETE',
	ADD_CHILD_LIBRARY: 'library/:parentLibraryID/POST',
	UPDATE_CHILD_LIBRARY: 'library/:parentLibraryID/PATCH',
	DELETE_CHILD_LIBRARY: 'library/:parentLibraryID/DELETE',
	ADD_GROUPS: 'library/:parentLibraryID/addGroups/PATCH',
	REMOVE_GROUPS: 'library/:parentLibraryID/removeGroups/PATCH',
};

const LibrarySubscriptions = props => {
	const {
		socket,
		libraryID,
		updateLibrary,
		addMedia,
		updateMedia,
		removeMedia,
		addChildLibrary,
		updateChildLibrary,
		deleteChildLibrary,
		addGroups,
		removeGroups,
	} = props;
	const propsToSend = { ...props };
	delete propsToSend.children;
	delete propsToSend.eventID;

	const subscribe = () => {
		console.log('⚡️ AppLibrarySubscriptions Mounted ⚡️');
		if (libraryID) socket.emit('app/library/:libraryID/SUBSCRIBE', { libraryID });
	};

	const unsubscribe = prevLibraryID => {
		console.log(`🚶‍♂️ AppLibrarySubscriptions Unmounted 🚶‍♂️`);
		socket.emit('app/library/:libraryID/UNSUBSCRIBE', { libraryID: prevLibraryID || libraryID });
	};

	//(name, sort order, hidden)
	const listenForUpdatedLibrary = () => {
		socket.on(WS_LIBRARY_BY_HTTP.UPDATE_LIBRARY, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating library', error);
			else {
				updateLibrary(data);
				console.log('Updated Library', data);
			}
		});
	};

	//child library added
	const listenForAddedChildLibrary = () => {
		socket.on(WS_LIBRARY_BY_HTTP.ADD_CHILD_LIBRARY, payload => {
			const { data, error } = payload;
			if (error) console.log('Error adding child library', error);
			else {
				addChildLibrary(data);
				console.log('Added child library', data);
			}
		});
	};

	//library child updated
	const listenForUpdatedChildLibrary = () => {
		socket.on(WS_LIBRARY_BY_HTTP.UPDATE_CHILD_LIBRARY, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating child library', error);
			else {
				console.log('HELLO');
				updateChildLibrary(data);
				console.log('Updated Child Library', data);
			}
		});
	};

	//child library deleted
	const listenForDeletedChildLibrary = () => {
		socket.on(WS_LIBRARY_BY_HTTP.DELETE_CHILD_LIBRARY, payload => {
			const { data, error } = payload;
			if (error) console.log('Error deleteing child library', error);
			else {
				const { id } = data;
				deleteChildLibrary(id);
				console.log('Deleted child library', data);
			}
		});
	};

	//group added to library
	const listenForAddedGroups = () => {
		socket.on(WS_LIBRARY_BY_HTTP.ADD_GROUPS, payload => {
			const { data, error } = payload;
			if (error) console.log('Error adding groups to child library', error);
			else {
				const { libraryID, groups } = data;
				addGroups(libraryID, groups);
				console.log('Added groups to child library', data);
			}
		});
	};

	//group removed from library
	const listenForRemovedGroups = () => {
		socket.on(WS_LIBRARY_BY_HTTP.REMOVE_GROUPS, payload => {
			const { data, error } = payload;
			if (error) console.log('Error removing groups from child library', error);
			else {
				const { libraryID, groupIDs } = data;
				removeGroups(libraryID, groupIDs);
				console.log('Removed groups from child library', data);
			}
		});
	};

	//media removed or deleted
	const listenForAddedMedia = () => {
		socket.on(WS_LIBRARY_BY_HTTP.ADD_MEDIA, payload => {
			const { data, error } = payload;
			if (error) console.log('Error adding media', error);
			else {
				addMedia(data);
				console.log('Added Media', data);
			}
		});
	};

	//media is updated (name, src)
	const listenForUpdatedMedia = () => {
		socket.on(WS_LIBRARY_BY_HTTP.UPDATE_MEDIA, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating media', error);
			else {
				const updatedMedia = { ...data };
				delete updatedMedia.should_cache;

				updateMedia(updatedMedia);
				console.log('Updated Media', data);
			}
		});
	};

	//media removed or deleted
	const listenForRemovedMedia = () => {
		socket.on(WS_LIBRARY_BY_HTTP.REMOVE_MEDIA, payload => {
			const { data, error } = payload;
			if (error) console.log('Error removing media', error);
			else {
				const { id } = data;
				removeMedia(id);
				console.log('Removed Media', data);
			}
		});
	};

	const listeners = () => [
		listenForUpdatedLibrary,
		listenForAddedMedia,
		listenForUpdatedMedia,
		listenForRemovedMedia,
		listenForAddedChildLibrary,
		listenForUpdatedChildLibrary,
		listenForDeletedChildLibrary,
		listenForAddedGroups,
		listenForRemovedGroups,
	];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				sub={subscribe}
				unsub={libraryID => unsubscribe(libraryID)}
				listeners={listeners()}
				routes={Object.values(WS_LIBRARY_BY_HTTP)}
				from={'library'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = state => ({
	socket: state.ws.socket,
});

const mdp = dispatch => ({
	updateLibrary: library => dispatch(updateLibrary(library)),
	addMedia: media => dispatch(addMedia(media)),
	updateMedia: media => dispatch(updateMedia(media)),
	removeMedia: mediaID => dispatch(removeMedia(mediaID)),
	updateChildLibrary: library => dispatch(updateChildLibrary(library)),
	addChildLibrary: library => dispatch(addChildLibrary(library)),
	deleteChildLibrary: libraryID => dispatch(deleteChildLibrary(libraryID)),
	addGroups: (libraryID, groups) => dispatch(addGroupsToLibrary(libraryID, groups)),
	removeGroups: (libraryID, groupIDs) => dispatch(removeGroupsFromLibrary(libraryID, groupIDs)),
});

export default connect(msp, mdp)(LibrarySubscriptions);

// Library Subscription when library stack is loaded
// media should be object inside library
// why is setLibrary being called when single media is selected?
