export const CREATE_PAGE = 'CREATE_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const DELETE_PAGE = 'DELETE_PAGE';

export const addPage = ({ data, eventID }, from) => ({
	type: CREATE_PAGE,
	from,
	page: data,
	currentEventID: eventID,
});

export const updatePage = ({ data, eventID }, from) => ({
	type: UPDATE_PAGE,
	from,
	page: data,
	currentEventID: eventID,
});

export const deletePage = ({ pageID, eventID }, from) => ({
	type: DELETE_PAGE,
	from,
	pageID,
	currentEventID: eventID,
});
