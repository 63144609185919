import firebase from 'firebase/app';

export const initializeFirebase = () => {
	firebase.initializeApp({
		apiKey: 'AIzaSyDagPqH1dmgglDnvOy6-T8nuYixIF8PT-c',
		authDomain: 'blueprintweb-c2b0c.firebaseapp.com',
		databaseURL: 'https://blueprintweb-c2b0c.firebaseio.com',
		projectId: 'blueprintweb-c2b0c',
		storageBucket: 'blueprintweb-c2b0c.appspot.com',
		messagingSenderId: '1098043814745',
		appId: '1:1098043814745:web:68442dc5a867ede7ae1248',
		measurementId: 'G-SJFSRG663K',
	});

	// use other service worker
	// navigator.serviceWorker
	//   .register('/my-sw.js')
	//   .then((registration) => {
	//     firebase.messaging().useServiceWorker(registration);
	//   });
};

export const askForPermissioToReceiveNotifications = async () => {
	try {
		const messaging = firebase.messaging();

		await messaging.requestPermission();
		const token = await messaging.getToken();
		console.log('user token: ', token);

		return token;
	} catch (error) {
		console.error(error);
	}
};
