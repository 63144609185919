import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';

import { persistor, store } from './store';
import App from './containers';

window.wa = { getState: store.getState }; // UNCOMMENT FOR DEVELOPMENT

export default class ReduxWrapper extends Component {
	render() {
		return (
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<Router>
						<App />
					</Router>
				</PersistGate>
			</Provider>
		);
	}
}
