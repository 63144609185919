import { INCREASE_UNREAD_COUNT, SET_UNREAD_COUNTS } from '../actions';

const defaultState = {
	unread_announcements: 0,
	unread_app_notifications: 0,
};

const unreadReducer = (state = defaultState, action) => {
	const { type, unreadCounts, announcementOrNotification } = action;
	const stateDup = { ...state };
	switch (type) {
		case SET_UNREAD_COUNTS:
			return { ...unreadCounts };
		case INCREASE_UNREAD_COUNT:
			if (announcementOrNotification === 'notification') stateDup.unread_app_notifications++;
			else if (announcementOrNotification === 'announcement') stateDup.unread_announcements++;
			return { ...stateDup };
		default:
			return state;
	}
};

export default unreadReducer;
