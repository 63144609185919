let Constants = {
	IS_DEVELOPMENT: false,
	CLIENT_ID: '1',
	// SSO_TEXT: 'Click Here To Test SSO Login',
	APP_NAME: 'Bluebird Events',
	BPNYC_API_SERVER_URL: 'https://server.bluebirdadmin.com',
	BPNYC_API_LOCAL_SERVER_URL: 'http://localhost:8080',
	BPNYC_WS_URL: 'https://eca-websockets.bpdev.club/',
	BPNYC_WS_LOCAL_URL: 'http://localhost:4012',
	ASSETS_FOLDER: 'assets/blueprint/bluebird/',
	DOMAIN: 'https://app.bluebird.events',
	CACHE: 'never',
	SUPPORT_EMAIL: 'support@blueprintnyc.zendesk.com',
	LOGIN_TEXT: 'Admin Login',
	PASSED_EVENT: false,
	AI_PHOTOBOOTH_DOMAIN: 'https://ai-photo-booth.bpdev.club',
};
if (window.location.host.includes('localhost')) {
	Constants['AI_PHOTOBOOTH_DOMAIN'] = 'http://localhost:3009';
}

if (window.location.host.includes('staging.bluebird.events')) {
	Constants['DOMAIN'] = 'https://staging.bluebird.events';
	Constants['BPNYC_API_SERVER_URL'] = 'https://staging.server.bluebirdadmin.com';
	Constants['BPNYC_WS_URL'] = 'https://eca-websockets-staging.bpdev.club/';
}

export const getDomainBranding = async domain => {
	const url = window.location.origin;
	let localURL = '';
	if (
		process.env.NODE_ENV === 'staging' ||
		window.location.host.includes('staging.bluebird.events')
	) {
		console.log('IS STAGING');
		localURL = 'staging.staging'; //the backend ignores subdomains, so we have to do this
	} else if (process.env.NODE_ENV !== 'production') {
		console.log('IS LOCAL');
		//for local testing -> comment line in and switch out url
		// localURL = 'staging.staging';
		// localURL = 'app.bluebird.events';
		// localURL = 'ucbdermnation.com';
		// localURL = 'azairacademy.com';
		// localURL = 'lantheusmeetings.com';
		// localURL = 'deltamobiletour.com';
		// localURL = 'azunstoppable.com';
	}

	console.log('URL', localURL ? localURL : url);

	const res = await domain(localURL ? localURL : url);
	if (res.domainByURL) getClientBranding(res.domainByURL);
	else getLocalBranding();

	if (localURL) {
		getLocalBranding();
	}

	const redirectURL = localURL ? 'http://localhost:3001' : Constants['DOMAIN'];

	Constants['SSO'] = Constants['SSO_TEXT']
		? 'https://sso.bluebirdadmin.com/?client=' +
		  Constants['CLIENT_ID'] +
		  '&mobile=' +
		  encodeURIComponent(false) +
		  '&redirect=' +
		  encodeURIComponent(redirectURL + '/sso') +
		  '&referrer=' +
		  encodeURIComponent(window.location.host)
		: null;

	return Constants;
};

export const getLocalBranding = () => {
	Constants['DOMAIN'] = window.location.origin;
	Constants['BPNYC_API_SERVER_URL'] = 'http://localhost:8080';
};

export const getClientBranding = appDomain => {
	const domainBranding = JSON.parse(appDomain.branding);
	const favicon = document.getElementById('favicon');

	document.title = appDomain.app_name;
	Constants.CLIENT_ID = appDomain.client_id;

	const images = ['background', 'logo', 'nav_logo', 'favicon'];
	for (let key in domainBranding) {
		if (images.includes(key)) {
			Constants[key.toUpperCase()] = domainBranding[key].src || domainBranding[key];
			if (key === 'favicon' && domainBranding['favicon']) {
				favicon.href = domainBranding['favicon'].src || domainBranding['favicon'];
			}
		} else Constants[key.toUpperCase()] = domainBranding[key];
	}
};
console.log('CONSTANTS', Constants);
export default Constants;
