// import {AsyncStorage} from '@react-native-community/async-storage';
// import {Buffer} from 'buffer';
// import * as https from 'https';

import { store } from '../store';

class Api {
	static headers() {
		return {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			// Authorization: store.getState().user.token ? 'Bearer ' + 'checkAuth' : '',
			Authorization: store.getState().user.token ? 'Bearer ' + store.getState().user.token : '',
		};
	}
	static msg(msg) {
		return console.warn(msg);
	}
	static get = async (endpointURL, isjsonResponse) => {
		return await this.func(endpointURL, null, 'GET', isjsonResponse);
	};

	static put = async (endpointURL, params, isjsonResponse) => {
		return await this.func(endpointURL, params, 'PUT', isjsonResponse);
	};
	static patch = async (endpointURL, params, isjsonResponse) => {
		return await this.func(endpointURL, params, 'PATCH', isjsonResponse);
	};

	static post = async (endpointURL, params, isjsonResponse) => {
		return await this.func(endpointURL, params, 'POST', isjsonResponse);
	};

	static delete = async (endpointURL, params, isjsonResponse) => {
		return await this.func(endpointURL, params, 'DELETE', isjsonResponse);
	};

	static func = async (url, data, method, isjsonResponse = false) => {
		let request = {
			method: method,
			headers: Api.headers(),
			body: JSON.stringify(data),
		};
		console.log('post request started', url, request);
		try {
			// const responseBody = await axios({
			//   method,
			//   url,
			//   data,
			// });
			const responseBody = await fetch(url, request);
			// return {status: responseBody.status, data: responseBody.data};
			// console.log(responseBody.status);
			// console.log(responseBody);

			if (responseBody.status >= 200 && responseBody.status <= 299) {
				if (responseBody.status === 204) {
					return { status: responseBody.status, data: { status: 'success' } };
				} else {
					let response = isjsonResponse ? await responseBody.json() : await responseBody.text();
					return { status: responseBody.status, data: response };
				}
			} else {
				let response = isjsonResponse ? await responseBody.json() : await responseBody.text();
				let returnObj = { status: responseBody.status, data: response };
				if (responseBody.status === 404) {
					returnObj.error = 'not found';
				}
				return returnObj;
			}
		} catch (error) {
			// console.log(error);
			console.log(error);
			let data = {};
			if (error.response) {
				data = error.response.data;
			} else {
				data = {
					error: {
						status: true,
						message: 'Could not get any response from server',
					},
				};
				alert(data.error.message);
			}
			return {
				status: error.response ? error.response.status : 500,
				data,
			};
		}
	};
}

export default Api;
