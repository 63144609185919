import socketIOClient from 'socket.io-client';
import Constants from 'AppConfig';

let wsURL =
	process.env.NODE_ENV !== 'production' ? Constants.BPNYC_WS_LOCAL_URL : Constants.BPNYC_WS_URL;

export default socket => {
	if (!socket)
		return socketIOClient(wsURL, {
			forceNew: true,
			transports: ['websocket'],
			upgrade: false,
		});
};
