import Constants from '../AppConfig';

const baseURL = Constants.BPNYC_API_SERVER_URL;
const localURL = Constants.BPNYC_API_LOCAL_SERVER_URL;
let hostString;
let graphQL_EndPointHost;
if (process.env.NODE_ENV !== 'production') {
	hostString = `${localURL}`;
	graphQL_EndPointHost = `${localURL}`;
} else {
	hostString = `${baseURL}`;
	graphQL_EndPointHost = `${baseURL}`;
}
const SSOLogin = 'https://www.google.com';

export const host = hostString;
export const graphQL_EndPoint = `${graphQL_EndPointHost}/graphql/client/${Constants.CLIENT_ID}`;
export const routes = {
	SSOLogin,
	pushToken: `${host}/pushToken`,
	passwordReset: `${host}/api/password`,
};
