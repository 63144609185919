import { arrToObj } from 'common';
import {
	SET_NOTIFICATION_TYPES,
	SET_EVENT_PREFERENCES,
	SET_ATTENDEE_PREFERENCES,
	UPDATE_ATTENDEE_PREFERENCES,
} from 'redux/actions';

const initialState = {
	notificationTypes: [],
	eventPreferences: {},
	attendeePreferences: {},
};
const notificationPreferencesReducer = (state = initialState, action) => {
	const stateDup = { ...state };
	const {
		type,
		notificationTypes,
		eventNotificationPreferences,
		attendeeNotificationPreferences,
		attendeePreference,
	} = action;
	switch (type) {
		case SET_NOTIFICATION_TYPES:
			return { ...stateDup, notificationTypes };
		case SET_EVENT_PREFERENCES:
			return {
				...stateDup,
				eventPreferences: arrToObj(eventNotificationPreferences, null, 'notification_type_id'),
			};
		case SET_ATTENDEE_PREFERENCES:
			return {
				...stateDup,
				attendeePreferences: arrToObj(
					attendeeNotificationPreferences,
					null,
					'notification_type_id',
				),
			};
		case UPDATE_ATTENDEE_PREFERENCES:
			const { notification_type_id } = attendeePreference;
			stateDup.attendeePreferences[notification_type_id] = attendeePreference;
			return { ...stateDup, attendeePreferences: { ...stateDup.attendeePreferences } };
		default:
			return state;
	}
};

export default notificationPreferencesReducer;
