import { AnnouncementsServices } from '../../services';

export const ANNOUNCEMENT = 'ANNOUNCEMENT';
export const UNREADCOUNT = 'UNREADCOUNT';
export const SET_PINNED_ANNOUNCEMENTS = 'SET_PINNED_ANNOUNCEMENTS';
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';

//Setters
export const setAnnouncements = (announcements, pageInfo, refresh) => ({
	type: ANNOUNCEMENT,
	announcements,
	pageInfo,
	refresh,
});

export const setUnreadCount = unreadCount => ({
	type: UNREADCOUNT,
	unreadCount,
});

export const setPinnedAnnouncements = pinned => ({
	type: SET_PINNED_ANNOUNCEMENTS,
	pinned,
});

export const addAnnouncement = announcement => ({
	type: ADD_ANNOUNCEMENT,
	announcement,
});

export const updateAnnouncement = announcement => ({
	type: UPDATE_ANNOUNCEMENT,
	announcement,
});

export const deleteAnnouncement = announcementID => ({
	type: DELETE_ANNOUNCEMENT,
	announcementID,
});

//Getters
export const fetchAnnouncements = (offSets, event_id, refresh) => async dispatch => {
	let { error, data } = await AnnouncementsServices.Announcements(offSets, event_id);
	if (data) {
		const announcements = data.myAnnouncements.nodes;
		const pageInfo = data.myAnnouncements.page_info;
		dispatch(setAnnouncements(announcements, pageInfo, refresh));
		return data;
	} else {
		console.log('error: ', error);
	}
};

export const getUnreadAnnouncements = params => async dispatch => {
	let { error, data } = await AnnouncementsServices.GetUnreadAnnouncements(params);
	if (data) {
		let unreadCount = data.getUnreadAnnouncements.nodes.length;
		if (unreadCount >= 999) unreadCount = 0;
		dispatch(setUnreadCount(unreadCount));
		return unreadCount;
	} else {
		console.log('error: ', error);
	}
};

export const setAllAnnouncementsAsRead = params => async dispatch => {
	let { error, data } = await AnnouncementsServices.SetAllAnnouncementsAsRead(params);
	if (data) {
		dispatch(setUnreadCount(0));
		return data;
	} else {
		console.log('error: ', error);
	}
};

export const getPinnedAnnouncements = (event_id, attendee_id) => async dispatch => {
	let { error, data } = await AnnouncementsServices.GetPinnedAnnouncements(event_id, attendee_id);
	if (data) {
		const pinnedAnnouncements = data.pinnedAnnouncements.nodes;
		dispatch(setPinnedAnnouncements(pinnedAnnouncements));
		return pinnedAnnouncements;
	} else {
		console.log('error: ', error);
	}
};
