import GraphQLController from './GraphQL';
import { Constants } from '../common';
import CheckInsGQL from './gql/CheckInsGQL';

export default {
	CheckIns: async params => {
		const { event_id, attendee_id } = params;
		let variables = { client_id: Constants.CLIENT_ID, event_id, attendee_id };
		let mutation = CheckInsGQL.CheckIns;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
};
