import { gql } from 'graphql-tag';

export default {
	AddAuditRecordGQL: gql`
		mutation addAuditRecord($audit: AuditInput!) {
			addAuditRecord(audit: $audit) {
				id
				auth_id
				auth_type
				name
				email
				action
				event_id
				payload
				details
				updated_at
				created_at
			}
		}
	`,
};
