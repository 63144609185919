import GraphQLController from './GraphQL';
import { Constants } from '../common';
import ConnectionsGQL from './gql/ConnectionsGQL';

export default {
	Connections: async params => {
		const { event_id, attendee_id } = params;
		let variables = { client_id: Constants.CLIENT_ID, event_id, attendee_id };
		let mutation = ConnectionsGQL.Connections;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	Connect: async params => {
		const { event_id, attendee_ids } = params;
		let variables = { event_id, attendee_ids, client_id: Constants.CLIENT_ID };
		let mutation = ConnectionsGQL.Connect;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
};
