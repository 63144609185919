import GraphQLController from './GraphQL';
import AuditGQL from './gql/AuditGQL';
import { store } from '../store';

export default {
	AddAuditRecord: async query => {
		if (!query || !query.audit) {
			console.error('no audit record passed');
			return;
		}
		const { event } = store.getState().events;
		if (!event || !event.id) {
			console.error('no event found');
			return;
		}
		const payload = query.audit.payload || {};
		let variables = {
			audit: {
				event_id: event.id,
				type: 'web',
				...query.audit,
				payload: JSON.stringify(payload),
			},
		};
		// console.log('AddAuditRecord variables');
		// console.log(variables);
		let mutation = AuditGQL.AddAuditRecordGQL;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
};
