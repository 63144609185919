import { gql } from 'graphql-tag';

export default {
	Domain: gql`
		query domainByURL($url: String) {
			domainByURL(url: $url) {
				id
				client_id
				app_name
				domain
				branding
			}
		}
	`,
};
