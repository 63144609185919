import { gql } from 'graphql-tag';

export default {
	Announcements: gql`
		query myAnnouncements($offset: Uint, $event_id: Uint) {
			myAnnouncements(offset: $offset, event_id: $event_id) {
				nodes {
					id
					name
					body
					link
					send_at
					pinned
					event_id
				}
				page_info {
					total_count
					has_next_page
				}
			}
		}
	`,
	SetAllAnnouncementsAsRead: gql`
		mutation setAllAnnouncementsAsRead($event_id: Uint!, $attendee_id: Uint!) {
			setAllAnnouncementsAsRead(event_id: $event_id, attendee_id: $attendee_id) {
				nodes {
					id
					event_id
				}
			}
		}
	`,
	GetUnreadAnnouncements: gql`
		query getUnreadAnnouncements($event_id: Uint!, $attendee_id: Uint!) {
			getUnreadAnnouncements(event_id: $event_id, attendee_id: $attendee_id) {
				nodes {
					id
					name
					body
					link
					send_at
					pinned
					event_id
				}
			}
		}
	`,
	GetPinnedAnnouncements: gql`
		query pinnedAnnouncements($event_id: Uint, $attendee_id: Uint) {
			pinnedAnnouncements(event_id: $event_id, attendee_id: $attendee_id) {
				nodes {
					id
					name
					body
					link
					send_at
					sent
					pinned
					open_rate
					groups {
						id
						name
						event_id
					}
					updated_at
					created_at
				}
			}
		}
	`,
};
