import { KudosServices } from '../../services';
import { message } from 'antd';

export const SET_KUDOS_LIST = 'SET_KUDOS_LIST';
export const SET_BADGES_LIST = 'SET_BADGES_LIST';
export const ADD_KUDO_BADGE = 'ADD_KUDO_BADGE';
export const UPDATE_KUDO_BADGE = 'UPDATE_KUDO_BADGE';
export const DELETE_KUDO_BADGE = 'DELETE_KUDO_BADG';
export const ADD_GROUPS_TO_KUDO = 'ADD_GROUPS_TO_KUDO';
export const REMOVE_GROUPS_FROM_KUDO = 'REMOVE_GROUPS_FROM_KUDO';
export const ASSIGN_BADGE_TO_ATTENDEE = 'ASSIGN_BADGE_TO_ATTENDEE';
export const LIKE_AND_DISLIKE_KUDO = 'LIKE_AND_DISLIKE_KUDO';

//Setters
const setKudosList = kudos => ({
	type: SET_KUDOS_LIST,
	kudos,
});

const setBadgesList = badges => ({
	type: SET_BADGES_LIST,
	badges,
});

export const addKudos = (kudo, kudosOrBadges) => ({
	type: ADD_KUDO_BADGE,
	kudo,
	kudosOrBadges,
});

export const updateKudos = (kudo, kudosOrBadges) => ({
	type: UPDATE_KUDO_BADGE,
	kudo,
	kudosOrBadges,
});

export const deleteKudos = (kudoID, kudosOrBadges) => ({
	type: DELETE_KUDO_BADGE,
	kudoID,
	kudosOrBadges,
});

export const addGroupsToKudos = (groups, kudosOrBadges, kudoID) => ({
	type: ADD_GROUPS_TO_KUDO,
	groups,
	kudosOrBadges,
	kudoID,
});

export const removeGroupsFromKudos = (groups, kudosOrBadges, kudoID) => ({
	type: REMOVE_GROUPS_FROM_KUDO,
	groups,
	kudosOrBadges,
	kudoID,
});

export const updateBadgeForAssignee = badge => ({
	type: ASSIGN_BADGE_TO_ATTENDEE,
	badge,
});

export const likeAndDislikeKudo = (kudoID, isLiked, kudoGiven) => ({
	type: LIKE_AND_DISLIKE_KUDO,
	kudoID,
	isLiked,
	kudoGiven,
});

//Getters
export const getKudos = (event_id, attendee_id) => async dispatch => {
	const { error, data } = await KudosServices.KudosList(event_id, attendee_id);
	if (data) {
		const { kudos } = data.attendeeKudosList;
		dispatch(setKudosList(kudos));
		return data;
	} else return error.mesage;
};

export const getBadges = (event_id, attendee_id) => async dispatch => {
	const { error, data } = await KudosServices.KudosList(event_id, attendee_id);
	if (data) {
		const { badges } = data.attendeeKudosList;
		dispatch(setBadgesList(badges));
		return data;
	} else return error.mesage;
};

export const likeDislikeKudosAction = (
	kudo_id,
	attendee_receiver_id,
	attendee_giver_id,
	is_like,
	kudo,
) => async () => {
	const { error, data } = await KudosServices.LikeDislikeKudos(
		kudo_id,
		attendee_receiver_id,
		attendee_giver_id,
		is_like,
	);
	if (error === 'kudo limit exceeded') {
		message.warning({
			content: `You have already given ${kudo.name} the maximum number of times. Limit ${kudo.limit} times.`,
		});
	}
	return data ? data : error;
};
