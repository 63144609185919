import { gql } from 'graphql-tag';

const userFields = `
				id
				email
				token
				push_token
				sso_id
				first_name
				last_name
				password
				company
				bio
				address
				city
				county
				country
				state
				postal_code
				avatar_url
				mobile_number
				phone_number
				facebook_url
				linkedin_url
				website_url
				twitter_url
				blog_url
				is_manager
				client_metadata
				events {
					id
					name
				}
				groups {
					id
					name
					track
					event_id
				}
				last_sync
				last_login
				created_at
				updated_at
				deleted_at
`;

export default {
	Login: gql`
		mutation login($email: String!, $password: String!, $client: String!, $device: DeviceEnum!) {
			login(email: $email, password: $password, client: $client, device: $device) {
				${userFields}
			}
		}
	`,
	Register: gql`
		mutation register($attendee: String!, $event_id: Uint!, $device: DeviceEnum!) {
			register(attendee: $attendee, event_id: $event_id, device: $device) {
				${userFields}
			}
		}
	`,
	AuthHistory: gql`
		mutation createOrUpdateAuthHistory($auth_history: AuthHistoryInput!) {
			createOrUpdateAuthHistory(auth_history: $auth_history) {
				device_uniq_id
				app_version
				app_build_version
			}
		}
	`,
	GetUserData: gql`
		query me {
			me {
				${userFields}
			}
		}
	`,
};
