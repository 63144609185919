import {
	SET_FEEDS,
	POST,
	DELETE_POST,
	LIKE,
	COMMENT,
	DELETE_COMMENT,
	EMPTY_POST,
	SET_PINNED_POSTS,
	EMPTY_FEED,
} from 'redux/actions';

// default state
const defaultFeedsState = {
	posts: { nodes: [], page_info: { has_next_page: false } },
	postsPerPage: 10,
	offset: 0,
};

const defaultPostState = {
	id: 0,
	comments: { nodes: [] },
	media: { nodes: [] },
	attendees_who_liked: { nodes: [] },
};

const initialState = {
	feeds: { ...defaultFeedsState },
	post: { ...defaultPostState },
};

// helpers
const updateAttendeesWhoLiked = (post, like) => {
	const { post_id, user, increment } = like;
	if (post.id === post_id) {
		const awl = post.attendees_who_liked.nodes;
		const attendeesWhoLiked =
			increment > 0 ? [...awl, user] : [...awl.filter(u => u.id !== user.id)];
		post.attendees_who_liked.nodes = attendeesWhoLiked;
		post.likes += increment;
	}
	return post;
};

const updatePostComments = (post, comment) => {
	const { parent_id } = comment;
	if (post.id === parent_id) {
		const commentExists = post.comments.nodes.some(c => {
			return (
				c.attendee_id === comment.attendee_id &&
				c.created_at === comment.created_at &&
				c.body === comment.body
			);
		});
		if (!commentExists) {
			post.comments.nodes = [...post.comments.nodes, comment];
		}
	}
	return post;
};

const deletePostComment = (post, comment_id) => {
	post.comments.nodes = [...post.comments.nodes.filter(c => c.id !== comment_id)];
	return post;
};

const updateFeedsNodes = (feed, nodes) => {
	if (feed && feed.posts) {
		feed.posts.nodes = [...nodes];
	}
	return feed;
};

const getPostNodesFromFeed = feed => {
	return feed && feed.posts && feed.posts.nodes ? [...feed.posts.nodes] : [];
};

const combineFeeds = (newFeed, oldFeedPostNodes) => {
	return {
		posts: {
			nodes: [...oldFeedPostNodes, ...newFeed.posts.nodes],
			page_info: newFeed.posts.page_info,
		},
	};
};

const feedsReducer = (state = initialState, action) => {
	Object.freeze(state);
	const stateDup = { ...state };
	const { type, data, pinned } = action;
	const postDup = { ...stateDup.post };
	const feedsDup = { ...stateDup.feeds };
	let feedPostsNodesDup = getPostNodesFromFeed(feedsDup);
	switch (type) {
		case SET_FEEDS: {
			const { feeds, offset } = data;
			if (feeds) {
				const combinedFeed = !offset ? { ...feeds } : combineFeeds(feeds, feedPostsNodesDup);
				return {
					...stateDup,
					feeds: {
						...stateDup.feeds,
						...combinedFeed,
						offset: combinedFeed.posts.nodes.length,
					},
				};
			} else {
				return { ...stateDup };
			}
		}
		case POST: {
			const { post } = data;
			if (feedPostsNodesDup.some(p => p.id === post.id)) {
				feedPostsNodesDup = feedPostsNodesDup.map(p => (p.id === post.id ? { ...p, ...post } : p));
			} else {
				feedPostsNodesDup.unshift(post);
			}
			const feeds = updateFeedsNodes(feedsDup, feedPostsNodesDup);
			return {
				...stateDup,
				post: { ...stateDup.post, ...post },
				feeds: { ...feeds, offset: feeds.posts.nodes.length },
			};
		}
		case DELETE_POST: {
			const { post_id } = data;
			const post = postDup.id === post_id ? { ...defaultPostState } : postDup;
			const feeds = updateFeedsNodes(
				feedsDup,
				feedPostsNodesDup.filter(post => post.id !== post_id),
			);
			return { ...stateDup, post, feeds: { ...feeds, offset: feeds.posts.nodes.length } };
		}
		case LIKE: {
			const { like } = data;
			const feeds = updateFeedsNodes(
				feedsDup,
				feedPostsNodesDup.map(post => updateAttendeesWhoLiked({ ...post }, like)),
			);
			const post = feeds.posts.nodes.filter(post => post.id === like.post_id)[0];
			return { ...stateDup, post, feeds };
		}
		case COMMENT: {
			const { comment } = data;
			const feeds = updateFeedsNodes(
				feedsDup,
				feedPostsNodesDup.map(post => updatePostComments({ ...post }, comment)),
			);
			const post = updatePostComments(postDup, comment);
			return { ...stateDup, post, feeds };
		}
		case DELETE_COMMENT: {
			const { comment_id } = data;
			const feeds = updateFeedsNodes(
				feedsDup,
				feedPostsNodesDup.map(post => deletePostComment({ ...post }, comment_id)),
			);
			const post = deletePostComment(postDup, comment_id);
			return { ...stateDup, post, feeds };
		}
		case EMPTY_POST:
			return { ...stateDup, post: { ...defaultPostState } };
		case SET_PINNED_POSTS:
			return { ...stateDup, pinned };
		case EMPTY_FEED:
			return {
				...stateDup,
				feeds: { ...defaultFeedsState },
			};
		default:
			return stateDup;
	}
};

export default feedsReducer;
