import ReactGA from 'react-ga4';

const InitAnalytics = trackingId => {
	ReactGA.initialize(trackingId);
	return ReactGA;
};

export const Enums = {
	login: 'login',
	pageView: 'page_view',
	search: 'search',
};

export default InitAnalytics;
