import React, { Component } from 'react';
import { Img } from 'react-image';
import { getSourcesForImage } from '../../common';

class ProgressiveImage extends Component {
	render() {
		const { src, alt, style, className, type } = this.props;
		const { thumbnail, small, original } = getSourcesForImage(src, type);
		const sources = [small, original];
		const thumb = (
			<img
				src={thumbnail}
				// onError={({ currentTarget }) => {
				// 	// if we're going to do this, we need to cancel subscriptions when the component unmounts
				// 	currentTarget.onerror = null;
				// 	currentTarget.remove();
				// }}
				alt={alt}
			/>
		);
		return small ? (
			<Img
				className={className}
				key={`image-${small || original}`}
				src={sources}
				loader={thumbnail ? thumb : null}
				alt={alt}
				style={style}
			/>
		) : (
			<img className={className} src={src} alt={alt} style={style} />
		);
	}
}

export default ProgressiveImage;
