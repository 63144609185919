import GraphQLController from './GraphQL';
import DomainGQL from './gql/DomainGQL';

export default {
	Domain: async url => {
		const query = DomainGQL.Domain;
		const result = await GraphQLController.Query(query, { url });
		return result;
	},
};
