import WidgetServices from 'services/WidgetServices';

export const SET_EVENT_WIDGETS = 'SET_EVENT_WIDGETS';

const setEventWidgets = (widgets, from) => ({
	type: SET_EVENT_WIDGETS,
	from,
	widgets,
});

export const fetchEventWidgets = (event_id, from) => async dispatch => {
	const { error, data } = await WidgetServices.widgets(event_id);
	if (data) {
		const { eventWidgets } = data;
		dispatch(setEventWidgets(eventWidgets, from));
		return eventWidgets;
	} else {
		return error;
	}
};
