import {
	ANNOUNCEMENT,
	UNREADCOUNT,
	SET_PINNED_ANNOUNCEMENTS,
	INCREASE_UNREAD_COUNT,
	ADD_ANNOUNCEMENT,
	UPDATE_ANNOUNCEMENT,
	DELETE_ANNOUNCEMENT,
} from 'redux/actions';
import { arrToObj } from 'common';

const initialState = {
	announcements: {},
	pinned: {},
	pageInfo: {},
};

const announcementsReducer = (state = initialState, action) => {
	const {
		type,
		unreadCount,
		announcements,
		announcement,
		announcementID,
		pinned,
		pageInfo,
		refresh,
	} = action;
	const stateDup = { ...state };

	switch (type) {
		case ANNOUNCEMENT:
			return {
				...state,
				announcements: refresh
					? { ...arrToObj(announcements) }
					: { ...stateDup.announcements, ...arrToObj(announcements) },
				pageInfo,
			};
		case UNREADCOUNT:
			return { ...state, unreadCount };
		case INCREASE_UNREAD_COUNT:
			const newUnreadCount = state.unreadCount + unreadCount;
			return { ...state, unreadCount: newUnreadCount };
		case SET_PINNED_ANNOUNCEMENTS:
			return { ...state, pinned: arrToObj(pinned) };
		case ADD_ANNOUNCEMENT:
			state.announcements[announcement.id] = announcement;
			if (announcement.pinned) state.pinned[announcement.id] = announcement;
			return { ...state, announcements: { ...state.announcements }, pinned: { ...state.pinned } };
		case UPDATE_ANNOUNCEMENT:
			const { id } = announcement;
			state.announcements[id] = { ...state.announcements[id], ...announcement };

			if (announcement.pinned) state.pinned[id] = announcement;
			if (!announcement.pinned && state.pinned[id]) delete state.pinned[id];

			return { ...state, announcements: { ...state.announcements }, pinned: { ...state.pinned } };
		case DELETE_ANNOUNCEMENT:
			delete state.announcements[announcementID];
			if (state.pinned[announcementID]) delete state.pinned[announcementID];
			return { ...state, announcements: { ...state.announcements }, pinned: { ...state.pinned } };
		default:
			return state;
	}
};

export default announcementsReducer;
