import { gql } from 'graphql-tag';

export default {
	Connect: gql`
		mutation connectUsers($client_id: Uint!, $event_id: Uint!, $attendee_ids: [Uint]) {
			connectUsers(client_id: $client_id, event_id: $event_id, attendee_ids: $attendee_ids) {
				id
				attendees {
					nodes {
						id
						email
						first_name
						last_name
						avatar_url
					}
				}
				event_id
				created_at
			}
		}
	`,
	Connections: gql`
		query connections($event_id: Uint!, $attendee_id: Uint!) {
			connections(event_id: $event_id, attendee_id: $attendee_id) {
				nodes {
					id
					event_id
					attendees {
						nodes {
							id
							email
							first_name
							last_name
							avatar_url
						}
					}
					created_at
					updated_at
				}
			}
		}
	`,
};
