import React, { Fragment, lazy, Suspense } from 'react';
import { notification, Spin } from 'antd';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { getDomainBranding } from '../../src/AppConfig';
import {
	WebAppSubscriptions,
	AttendeeSubscriptions,
	GroupSubscriptions,
} from 'components/WebsocketSubscriptions';
import { login } from 'redux/actions';
import WithAuth from 'components/AuthWrapper/WithAuth';
import Layouts from './Layouts';
// import { initializeFirebase } from '../push-notification';
// import registerServiceWorker, { unregister } from '../registerServiceWorker';

const Login = lazy(() => import('./Login'));
const Logout = lazy(() => import('./Logout'));
const SSOLoginCallback = lazy(() => import('./SSOLoginCallback'));
const PasswordReset = lazy(() => import('./PasswordReset'));
const Dashboard = lazy(() => import('./Dashboard'));
const SocialFeeds = lazy(() => import('./SocialFeeds'));
const SocialFeedPost = lazy(() => import('./SocialFeedPost'));
const EventDispatcher = lazy(() => import('./EventDispatcher'));
const EventSelection = lazy(() => import('./Event'));
const Directory = lazy(() => import('./Directory'));
const Profile = lazy(() => import('./Profile'));
const Agenda = lazy(() => import('./Agenda'));

const Connections = lazy(() => import('./Connections'));
const Checkins = lazy(() => import('./Checkins'));
const ScanQR = lazy(() => import('./ScanQR'));
const Announcements = lazy(() => import('./Announcements'));
const Notifications = lazy(() => import('./Notifications'));
const PageStack = lazy(() => import('./PageStack'));
const LibraryStack = lazy(() => import('./LibraryStack'));
const MediaStack = lazy(() => import('./MediaStack'));
const MediaStackSingle = lazy(() => import('./MediaStackSingle'));
const ExternalFrame = lazy(() => import('./ExternalFrame'));
const Broadcast = lazy(() => import('./Broadcast'));
const Photobooth = lazy(() => import('./Photobooth'));
const AIPhotobooth = lazy(() => import('./AIPhotobooth'));
const Settings = lazy(() => import('./Settings'));
class App extends React.PureComponent {
	constructor(props) {
		super(props);
		this.showBranding();
		this.state = {
			announcement: [],
			link: '',
		};
	}

	showBranding = async () => {
		const { domain, setDomainBranding } = this.props;
		try {
			const domainBranding = await getDomainBranding(domain);
			setDomainBranding(domainBranding);
		} catch (e) {
			console.log(e);
		}
	};

	openNotificationWithIcon = (type, data) => {
		notification[type]({
			message: data.title,
			description: data.body,
			onClick: () => {
				this.setState({ link: data.click_action });
			},
		});
	};
	onIdle = () => {
		console.log('user is idle');
		const { user } = this.props;
		if (!user.withSSO) {
			return <Navigate to="/logout" replace />;
		}
	};
	render() {
		const { domainBranding, user } = this.props;
		const attendeeID = user.user.id;
		const { groups } = user.user;
		const { APP_NAME } = domainBranding;
		const CVNSM = APP_NAME === 'CV National Sales Meeting';
		return (
			<Fragment>
				{CVNSM ? (
					<IdleTimer
						ref={ref => {
							this.idleTimer = ref;
						}}
						element={document}
						onIdle={this.onIdle}
						debounce={250}
						timeout={1000 * 60 * 60}
					/>
				) : null}

				<Suspense
					fallback={
						attendeeID ? (
							<Layouts>
								<div
									style={{
										width: '100%',
										height: '100vh',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Spin size="large" />
								</div>
							</Layouts>
						) : null
					}
				>
					<WebAppSubscriptions>
						<AttendeeSubscriptions attendeeID={attendeeID}>
							<GroupSubscriptions userGroups={groups}>
								<Routes>
									<Route path="/login" element={<Login />} />
									<Route path="/sso" element={<SSOLoginCallback />} />
									<Route path="/passwordreset" element={<PasswordReset />} />
									<Route path="/ExternalFrame/" element={<ExternalFrame />} />
									<Route path="/logout" element={<Logout />} />
									<Route
										path="/dashboard"
										element={
											<WithAuth>
												<Dashboard />
											</WithAuth>
										}
									/>
									<Route
										path="/pageStack/:page"
										element={
											<WithAuth>
												<PageStack />
											</WithAuth>
										}
									/>
									<Route
										path="/agenda"
										element={
											<WithAuth>
												<Agenda />
											</WithAuth>
										}
									/>
									<Route
										path="/connections"
										element={
											<WithAuth>
												<Connections />
											</WithAuth>
										}
									/>
									<Route
										path="/checkIn"
										element={
											<WithAuth>
												<Checkins />
											</WithAuth>
										}
									/>
									<Route
										path="/scanQR"
										element={
											<WithAuth>
												<ScanQR />
											</WithAuth>
										}
									/>
									<Route
										path="/announcements"
										element={
											<WithAuth>
												<Announcements />
											</WithAuth>
										}
									/>
									<Route
										path="/notifications"
										element={
											<WithAuth>
												<Notifications />
											</WithAuth>
										}
									/>
									<Route
										path="/post/:id"
										element={
											<WithAuth>
												<SocialFeedPost />
											</WithAuth>
										}
									/>
									<Route
										path="/feed"
										element={
											<WithAuth>
												<SocialFeeds />
											</WithAuth>
										}
									/>
									<Route
										path="/mediaStack/:mediaId"
										element={
											<WithAuth>
												<MediaStack />
											</WithAuth>
										}
									/>
									<Route
										path="/mediaStackSingle/:page"
										element={
											<WithAuth>
												<MediaStackSingle />
											</WithAuth>
										}
									/>
									<Route
										path="/broadcasts/:sessionId"
										element={
											<WithAuth>
												<Broadcast />
											</WithAuth>
										}
									/>
									<Route
										path="/photobooth/:camId"
										element={
											<WithAuth>
												<Photobooth />
											</WithAuth>
										}
									/>
									<Route
										path="/ai-photobooth/:pbId"
										element={
											<WithAuth>
												<AIPhotobooth />
											</WithAuth>
										}
									/>
									<Route
										path="/libraryStack/:page"
										element={
											<WithAuth>
												<LibraryStack />
											</WithAuth>
										}
									/>
									<Route
										path="/materials"
										element={
											<WithAuth>
												<LibraryStack isMaterialsRoute={true} />
											</WithAuth>
										}
									/>
									<Route
										path="/directory"
										element={
											<WithAuth>
												<Directory />
											</WithAuth>
										}
									/>
									<Route
										path="/settings"
										element={
											<WithAuth>
												<Settings />
											</WithAuth>
										}
									/>
									<Route
										path="/directory/profile/:username"
										element={
											<WithAuth>
												<Profile />
											</WithAuth>
										}
									/>
									<Route path="/attendeeDetail" element={<Profile />} />

									<Route
										path="/events"
										element={
											<WithAuth>
												<EventSelection />
											</WithAuth>
										}
									/>
									<Route
										path="/"
										element={
											<WithAuth>
												<EventDispatcher />
											</WithAuth>
										}
									/>
								</Routes>
							</GroupSubscriptions>
						</AttendeeSubscriptions>
					</WebAppSubscriptions>
				</Suspense>
			</Fragment>
		);
	}
}
const mapStateToProps = state => {
	return {
		user: state.user,
		domainBranding: state.domains.domainBranding,
	};
};

const mapDispatchToProps = dispatch => {
	const { domainActions } = require('../redux/DomainRedux');
	return {
		login: (user, token) => dispatch(login(user, token)),
		domain: url => dispatch(domainActions.domain(url)),
		setDomainBranding: domainBranding => dispatch(domainActions.setDomainBranding(domainBranding)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
