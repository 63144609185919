import { CheckInService } from '../services';

const types = {
	CHECKINS: 'CHECKINS',
};

export const CheckInRedux = {
	checkInsAction: params => async dispatch => {
		let { error, data } = await CheckInService.CheckIns(params);
		if (data) {
			dispatch({ type: types.CHECKINS, data: { checkIns: data } });
			return data;
		} else {
			return error.message;
		}
	},
};

const initialState = {
	checkIns: [],
};

export const reducer = (state = initialState, action) => {
	const { type, data } = action;
	switch (type) {
		case types.CHECKINS:
			const { checkIns } = data;
			return { ...state, checkIns: checkIns };
		default:
			return state;
	}
};
