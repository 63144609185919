import AgendaServices from '../../services/AgendaServices';

export const SET_AGENDA = 'SET_AGENDA';
export const ADD_AGENDA_ITEM = 'ADD_AGENDA_ITEM';
export const REMOVE_AGENDA_ITEM = 'REMOVE_AGENDA_ITEM';
export const UPDATE_AGENDA_ITEM = 'UPDATE_AGENDA_ITEM';

export const ADD_MATERIALS = 'ADD_MATERIALS';
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const ADD_GROUPS_TO_MATERIAL = 'ADD_GROUPS_TO_MATERIAL';
export const REMOVE_GROUPS_FROM_MATERIAL = 'REMOVE_GROUPS_FROM_MATERIAL';

const setAgenda = agenda => ({
	type: SET_AGENDA,
	agenda,
});

export const addAgendaItem = agendaItem => ({
	type: ADD_AGENDA_ITEM,
	agendaItem,
});

export const removeAgendaItem = agendaItemID => ({
	type: REMOVE_AGENDA_ITEM,
	agendaItemID,
});

export const updateAgendaItem = agendaItem => ({
	type: UPDATE_AGENDA_ITEM,
	agendaItem,
});

export const addMaterials = (materials, agendaItemID) => ({
	type: ADD_MATERIALS,
	materials,
	agendaItemID,
});

export const removeMaterial = (materialID, agendaItemID) => ({
	type: REMOVE_MATERIAL,
	materialID,
	agendaItemID,
});

export const updateMaterial = (material, agendaItemID) => ({
	type: UPDATE_MATERIAL,
	material,
	agendaItemID,
});

export const addMaterialGroups = (groupIDs, materialID, agendaItemID) => ({
	type: ADD_GROUPS_TO_MATERIAL,
	groupIDs,
	materialID,
	agendaItemID,
});

export const removeMaterialGroups = (groupIDs, materialID, agendaItemID) => ({
	type: REMOVE_GROUPS_FROM_MATERIAL,
	groupIDs,
	materialID,
	agendaItemID,
});

export const fetchAgenda = event_id => async dispatch => {
	let { error, data } = await AgendaServices.agenda(event_id);
	if (data) {
		const { agenda } = data;
		dispatch(setAgenda(agenda));
		return data;
	} else {
		return error;
	}
};

export const fetchGroupAgenda = (event_id, group_id) => async dispatch => {
	let { error, data } = await AgendaServices.groupAgenda(event_id, group_id);
	if (data) {
		const { agenda } = data;
		dispatch(setAgenda(agenda));
		return data;
	} else {
		return error;
	}
};
