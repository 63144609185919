import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import { setUser, updateAttendeeNotificationPreference } from 'redux/actions';
import { message } from 'antd';

const WS_EVENTS_BY_HTTP = {
	UPDATE_ATTENDEE: 'attendee/:attendeeID/PATCH',
	UPDATE_NOTIFICATION_SETTING: 'attendee/:attendeeID/notificationPreferences/PATCH',
};

const AttendeeSubscriptions = props => {
	const { socket, attendeeID, setUser, updatePreference } = props;
	const propsToSend = { ...props };
	delete propsToSend.children;
	delete propsToSend.eventID;
	const subscribe = () => {
		console.log('⚡️ AppAttendeeSubscriptions Mounted ⚡️');
		if (attendeeID) socket.emit('app/attendee/:attendeeID/SUBSCRIBE', { attendeeID });
	};

	const unsubscribe = prevAttendeeID => {
		console.log(`🚶‍♂️ AppAttendeeSubscriptions Unmounted 🚶‍♂️`);
		if (prevAttendeeID)
			socket.emit('app/attendee/:attendeeID/UNSUBSCRIBE', { attendeeID: prevAttendeeID });
	};

	const listenForUpdatedAttendee = () => {
		socket.on(WS_EVENTS_BY_HTTP.UPDATE_ATTENDEE, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating attendee', error);
			else {
				setUser(data, 'listenForUpdatedAttendee');
				console.log('This attendee has been updated!', data);
			}
		});
	};

	const listenForUpdatedNotificationSetting = () => {
		socket.on(WS_EVENTS_BY_HTTP.UPDATE_NOTIFICATION_SETTING, payload => {
			const { data, error } = payload;
			if (error) message.error('Could not update setting');
			else {
				updatePreference(data, 'listenForUpdatedNotificationSetting');
				message.success('Successfully updated setting');
			}
		});
	};

	const listeners = () => [listenForUpdatedAttendee, listenForUpdatedNotificationSetting];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				sub={subscribe}
				unsub={prevAttendeeID => unsubscribe(prevAttendeeID)}
				listeners={listeners()}
				routes={Object.values(WS_EVENTS_BY_HTTP)}
				from={'attendee'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = state => ({
	socket: state.ws.socket,
});

const mdp = dispatch => ({
	setUser: (user, from) => dispatch(setUser(user, from)),
	updatePreference: (preference, from) =>
		dispatch(updateAttendeeNotificationPreference(preference, from)),
});

export default connect(msp, mdp)(AttendeeSubscriptions);
