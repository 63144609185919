import { gql } from 'graphql-tag';

export default {
	NotificationTypes: gql`
		query notificationTypes {
			notificationTypes {
				id
				default
				type
			}
		}
	`,
	EventNotificationPreferences: gql`
		query eventNotificationPreferences($event_id: Uint) {
			eventNotificationPreferences(event_id: $event_id) {
				event_id
				notification_type_id
				subscribed
			}
		}
	`,
	AttendeeNotificationPreferences: gql`
		query attendeeEventNotificationPreferences($event_id: Uint, $attendee_id: Uint) {
			attendeeEventNotificationPreferences(event_id: $event_id, attendee_id: $attendee_id) {
				event_id
				attendee_id
				notification_type_id
				subscribed
			}
		}
	`,
};
