import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import { addNotification, increaseUnreadCount } from 'redux/actions';

const WS_NOTIF_BY_HTTP = {
	ADD_NEW_NOTIFICATION: '/notifications/addNotification/POST',
};

const NotificationSubscriptions = props => {
	const {
		socket,
		attendeeID,
		eventID,
		addNewNotification,
		increaseUnreadNotificationCount,
	} = props;
	const propsToSend = { ...props };
	delete propsToSend.children;

	const subscribe = () => {
		console.log('⚡️ Notification Subscription Mounted ⚡️');
		socket.emit('notifications/:eventID/:attendeeID/SUBSCRIBE', { eventID, attendeeID });
	};

	const unsubscribe = () => {
		console.log(`🚶‍♂️ Notification Subscription Unmounted 🚶‍♂️`, props);
		socket.emit('notifications/:eventID/:attendeeID/UNSUBSCRIBE', { eventID, attendeeID });
	};

	const listenForNewNotification = () => {
		socket.on(WS_NOTIF_BY_HTTP.ADD_NEW_NOTIFICATION, payload => {
			const { data, error } = payload;
			if (error) console.log('Error adding new notification', error);
			else {
				addNewNotification(data, 'NotificationSubscriptions');
				increaseUnreadNotificationCount('notification');
				console.log('Received new notification', data);
			}
		});
	};

	const listeners = () => [listenForNewNotification];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				sub={subscribe}
				unsub={prevAttendeeID => unsubscribe(prevAttendeeID)}
				listeners={listeners()}
				routes={Object.values(WS_NOTIF_BY_HTTP)}
				from={'notifications'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = state => ({
	socket: state.ws.socket,
});

const mdp = dispatch => ({
	addNewNotification: (notification, from) => dispatch(addNotification(notification, from)),
	increaseUnreadNotificationCount: type => dispatch(increaseUnreadCount(type)),
});

export default connect(msp, mdp)(NotificationSubscriptions);
