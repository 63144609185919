export const CREATE_OR_DUPLICATE_GROUP = 'CREATE_OR_DUPLICATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';

export const addGroup = ({ data, eventID }, from) => ({
	type: CREATE_OR_DUPLICATE_GROUP,
	from,
	group: data,
	currentEventID: eventID,
});

export const updateGroup = ({ data, eventID }, from) => ({
	type: UPDATE_GROUP,
	from,
	group: data,
	currentEventID: eventID,
});

export const deleteGroup = ({ groupID, eventID }, from) => ({
	type: DELETE_GROUP,
	from,
	groupID,
	currentEventID: eventID,
});
