import { gql } from 'graphql-tag';

export default {
	PostFeedGQL: gql`
		mutation createPost($post: PostInput!) {
			createPost(post: $post) {
				id
				attendee {
					id
					email
					first_name
					last_name
					avatar_url
				}
				body
				likes
				created_at
				comments {
					nodes {
						id
					}
				}
				attendees_who_liked {
					nodes {
						id
						first_name
						last_name
						avatar_url
					}
					page_info {
						total_count
					}
				}
				media {
					nodes {
						id
						name
						src
						mime_type
					}
				}
			}
		}
	`,

	PostCommentGQL: gql`
		mutation createComment($post: PostInput!) {
			createComment(post: $post) {
				id
				attendee {
					id
					email
					first_name
					last_name
					avatar_url
				}
				body
				likes
				created_at
			}
		}
	`,

	GetFeedsGQL: gql`
		query posts(
			$event_id: Uint
			$offset: Uint
			$items_per_page: Uint
			$search_query: String!
			$filter: String
		) {
			posts(
				event_id: $event_id
				offset: $offset
				items_per_page: $items_per_page
				search_query: $search_query
				filter: $filter
			) {
				nodes {
					id
					attendee {
						id
						email
						first_name
						last_name
						avatar_url
					}
					body
					likes
					pinned
					created_at
					comments {
						nodes {
							id
							body
							created_at
							attendee {
								id
								first_name
								last_name
								avatar_url
							}
						}
					}
					attendees_who_liked {
						nodes {
							id
							first_name
							last_name
							avatar_url
						}
						page_info {
							total_count
						}
					}
					media {
						nodes {
							id
							name
							src
							mime_type
						}
					}
				}
				page_info {
					total_count
					has_next_page
				}
			}
		}
	`,

	GetPostGQL: gql`
		query post($id: Uint!) {
			post(id: $id) {
				id
				attendee {
					id
					email
					first_name
					last_name
					avatar_url
				}
				body
				likes
				pinned
				comments {
					nodes {
						id
						body
						attendee {
							avatar_url
							id
							email
							first_name
							last_name
						}
						created_at
					}
				}
				attendees_who_liked {
					nodes {
						id
						first_name
						last_name
						avatar_url
					}
					page_info {
						total_count
					}
				}
				media {
					nodes {
						id
						name
						src
						mime_type
					}
				}
				created_at
			}
		}
	`,
	PostFeedLikeGQL: gql`
		mutation likePost($post_id: Uint!, $attendee_id: Uint!, $increment: Int!) {
			likePost(post_id: $post_id, attendee_id: $attendee_id, increment: $increment) {
				id
				likes
			}
		}
	`,
	GetFeedLikesGQL: gql`
		query post($id: Uint!) {
			post(id: $id) {
				id
				attendee {
					id
					email
					first_name
					last_name
					avatar_url
				}
				attendees_who_liked {
					nodes {
						id
						avatar_url
						first_name
						last_name
					}
					page_info {
						total_count
					}
				}
			}
		}
	`,
	UpdateFeedGQL: gql`
		mutation updatePost($post: PostInput!) {
			updatePost(post: $post) {
				id
				attendee {
					id
					email
					first_name
					last_name
					avatar_url
				}
				body
			}
		}
	`,
	DeleteFeedGQL: gql`
		mutation deletePost($id: Uint!) {
			deletePost(id: $id) {
				id
			}
		}
	`,
	DeleteComment: gql`
		mutation deletePost($id: Uint!) {
			deletePost(id: $id) {
				id
			}
		}
	`,
	getPinnedPosts: gql`
		query pinnedPosts($event_id: Uint) {
			pinnedPosts(event_id: $event_id) {
				nodes {
					id
					attendee {
						id
						email
						first_name
						last_name
						avatar_url
					}
					body
					likes
					pinned
					created_at
					comments {
						nodes {
							id
						}
					}
					media {
						nodes {
							id
							name
							src
							mime_type
						}
					}
				}
				page_info {
					total_count
					has_next_page
				}
			}
		}
	`,
};
