import { SET_CURRENT_CLIENT_ID, SET_CURRENT_EVENT_ID, CLEAR_EVENT } from '../actions';

const preState = {
	currentEventID: 'selection',
	currentClientID: null,
};

const contextReducer = (state = preState, action) => {
	Object.freeze(state);
	const stateDup = { ...state };
	const { type, clientID, eventID } = action;

	switch (type) {
		case SET_CURRENT_CLIENT_ID:
			return { ...stateDup, currentClientID: clientID };
		case SET_CURRENT_EVENT_ID:
			localStorage.setItem('currentEventID', eventID);
			return { ...stateDup, currentEventID: eventID };
		case CLEAR_EVENT:
			localStorage.removeItem('currentEventID');
			return { ...stateDup, currentEventID: 'selection' };
		default:
			return state;
	}
};

export default contextReducer;
