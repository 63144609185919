import React from 'react';

class SubscriptionTemplate extends React.Component {
	componentDidMount = () => {
		const { sub } = this.props;
		sub();
		this._handleSubscriptionsAndListeners();
	};

	async componentDidUpdate(prevProps) {
		const {
			eventID: prevEventID,
			attendeeID: prevAttendeeID,
			groupIDsRoom: prevGroupIDsRoom,
			libraryID: prevLibraryID,
		} = prevProps;
		const { eventID, attendeeID, libraryID, sub, unsub, from } = this.props;
		const updatedEventID = prevEventID !== eventID;
		const updatedAttendeeID = prevAttendeeID !== attendeeID;
		const updatedLibraryID = prevLibraryID !== libraryID;

		if (updatedEventID && from === 'event') {
			await unsub(prevEventID);
			if (eventID !== 'LOGOUT') await sub();
		}

		if (updatedAttendeeID && from === 'attendee') {
			await unsub(prevAttendeeID);
			await sub();
		}

		if (updatedAttendeeID && from === 'kudos') {
			await unsub(prevAttendeeID);
			await sub();
		}

		if ((updatedEventID || updatedAttendeeID) && from === 'groups') {
			await unsub(prevGroupIDsRoom);
			await sub();
		}

		if (updatedLibraryID && from === 'library') {
			await unsub(prevLibraryID);
			await sub();
		}

		if (updatedEventID && from === 'groups') {
			await this._removeListeners();
			await this._handleSubscriptionsAndListeners();
		}
	}
	componentWillUnmount = () => {
		const { unsub } = this.props;
		unsub();
		this._removeListeners();
		//const { listener } = this.state;
		//listener && listener.remove();
	};

	_removeListeners = () => {
		const { socket, routes } = this.props;
		routes.forEach(route => {
			socket.off(route);
		});
	};

	_handleSubscriptionsAndListeners = () => {
		const { listeners } = this.props;
		listeners.forEach(listener => listener());
	};

	render = () => <></>;
}

export default SubscriptionTemplate;
