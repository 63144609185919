import { NotificationPreferencesServices as PreferencesServices } from '../../services';

export const SET_NOTIFICATION_TYPES = 'FETCH_NOTIFICATION_TYPES';
export const SET_EVENT_PREFERENCES = 'FETCH_EVENT_PREFERENCES';
export const SET_ATTENDEE_PREFERENCES = 'FETCH_ATTENDEE_PREFERENCES';
export const UPDATE_ATTENDEE_PREFERENCES = 'UPDATE_ATTENDEE_PREFERENCES';

const setNotificationTypes = (notificationTypes, from) => ({
	type: SET_NOTIFICATION_TYPES,
	notificationTypes,
	from,
});

const setEventNotificationPreferences = (eventNotificationPreferences, from) => ({
	type: SET_EVENT_PREFERENCES,
	eventNotificationPreferences,
	from,
});

const setAttendeeNotificationPreferences = (attendeeNotificationPreferences, from) => ({
	type: SET_ATTENDEE_PREFERENCES,
	attendeeNotificationPreferences,
	from,
});

export const updateAttendeeNotificationPreference = (attendeePreference, from) => ({
	type: UPDATE_ATTENDEE_PREFERENCES,
	attendeePreference,
	from,
});
export const getNotificationTypes = from => async dispatch => {
	try {
		const { data } = await PreferencesServices.notificationTypes();
		const { notificationTypes } = data;
		dispatch(setNotificationTypes(notificationTypes, from));
	} catch (error) {
		console.log('np.actions.getNotificationTypes', error);
		return error.message;
	}
};

export const getEventNotificationPreferences = (eventID, from) => async dispatch => {
	try {
		const { data } = await PreferencesServices.eventNotificationPreferences({ event_id: eventID });
		const { eventNotificationPreferences } = data;
		dispatch(setEventNotificationPreferences(eventNotificationPreferences, from));
	} catch (error) {
		console.log('np.actions.getEventNotificationPreferences', error);
		return error.message;
	}
};

export const getAttendeeNotificationPreferences = (eventID, attendeeID, from) => async dispatch => {
	try {
		const { data } = await PreferencesServices.attendeeNotificationPreferences({
			event_id: eventID,
			attendee_id: attendeeID,
		});
		const { attendeeEventNotificationPreferences } = data;
		dispatch(setAttendeeNotificationPreferences(attendeeEventNotificationPreferences, from));
	} catch (error) {
		console.log('np.actions.getAttendeeNotificationPreferences', error);
		return error.message;
	}
};
