import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NotificationPreferencesWeb from './NotificationPreferencesWeb';
import NotificationPreferencesMobile from './NotificationPreferencesMobile';
import './NotificationPreferences.scss';

import {
	getNotificationTypes,
	getEventNotificationPreferences,
	getAttendeeNotificationPreferences,
} from 'redux/actions';
import { capitalize, isMobile } from 'common';

const NotificationPreferencesContainer = props => {
	const {
		getNotificationTypes,
		getEventPreferences,
		getAttendeePreferences,
		eventID,
		userID,
		clientID,
		socket,
		auth0Token,
	} = props;
	const mobileView = isMobile();

	useEffect(() => {
		const from = 'NotificationPreferences.jsx - useEffect';
		getNotificationTypes(from);
		getEventPreferences(eventID, from);
		getAttendeePreferences(eventID, userID, from);
	}, [getNotificationTypes, getEventPreferences, getAttendeePreferences, eventID, userID]);

	const handleChange = preference => {
		const { subscribed, notification_type_id } = preference;
		const attendeePreference = {
			event_id: eventID,
			attendee_id: userID,
			notification_type_id,
			subscribed: !subscribed,
		};
		socket.emit(
			'attendee/:attendeeID/notificationPreferences/PATCH',
			attendeePreference,
			clientID,
			auth0Token,
		);
	};

	const formatData = (defaultPreferences, eventPreferences, attendeePreferences) => {
		const formattedData = defaultPreferences.reduce((accum, notificationType) => {
			const [notif, method] = notificationType.type.split('_');

			if (!accum[notif]) accum[notif] = { type: capitalize(notif) };

			const attendeeOrEventPreference =
				attendeePreferences[notificationType.id] || eventPreferences[notificationType.id];
			accum[notif][method] = attendeeOrEventPreference
				? {
						subscribed: attendeeOrEventPreference.subscribed,
						notification_type_id: notificationType.id,
				  }
				: { subscribed: notificationType.default, notification_type_id: notificationType.id };
			return accum;
		}, {});

		return formattedData;
	};

	return (
		<div className={'NotificationPreferencesContainer'}>
			{mobileView ? (
				<NotificationPreferencesMobile
					handleChange={handleChange}
					formatData={formatData}
					{...props}
				/>
			) : (
				<NotificationPreferencesWeb
					handleChange={handleChange}
					formatData={formatData}
					{...props}
				/>
			)}
		</div>
	);
};

const msp = ({ user, context, ws, notificationPreferences, events }) => ({
	eventID: context.currentEventID,
	userID: user.user.id,
	socket: ws.socket,
	notificationPreferences,
	auth0Token: user.token,
	clientID: events.event.client_id,
});
const mdp = dispatch => ({
	getNotificationTypes: from => dispatch(getNotificationTypes(from)),
	getEventPreferences: (eventID, from) => dispatch(getEventNotificationPreferences(eventID, from)),
	getAttendeePreferences: (eventID, attendeeID, from) =>
		dispatch(getAttendeeNotificationPreferences(eventID, attendeeID, from)),
});

export default connect(msp, mdp)(NotificationPreferencesContainer);
