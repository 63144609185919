import { DirectoryServices } from '../../services';

export const DIRECTORIES = 'DIRECTORIES';
export const ATTENDEE = 'ATTENDEE';
export const EVENT_ATTENDEE = 'EVENT_ATTENDEE';
export const SET_ATTENDEE_POINTS = 'SET_ATTENDEE_POINTS';

//Setters
const setDirectory = (directories, offset) => ({
	type: DIRECTORIES,
	directories,
	offset,
});

const setAttendee = attendee => ({
	type: ATTENDEE,
	attendee,
});

const setEventAttendee = eventAttendee => ({
	type: EVENT_ATTENDEE,
	eventAttendee,
});

const setAttendeePoints = points => ({
	type: SET_ATTENDEE_POINTS,
	points,
});

//Getters
export const fetchDirectory = params => async dispatch => {
	let { error, data } = await DirectoryServices.Directories(params);
	if (data) {
		const { offset } = params;
		const { eventAttendeesDirectory } = data;
		dispatch(setDirectory(eventAttendeesDirectory, offset));
		return data;
	} else {
		return error.message;
	}
};

export const fetchAttendee = (id, event_id) => async dispatch => {
	let { error, data } = await DirectoryServices.Attendee(id);
	try {
		const res = await DirectoryServices.EventAttendeeMetadata(id, event_id);
		const eventAttendeeMetadata = JSON.parse(res.data.eventAttendeeMetadata.metadata);
		data.attendee['metadata'] = eventAttendeeMetadata;

		const pointRes = await DirectoryServices.EventAttendeePoints(id, event_id);
		const { attendeePoints } = pointRes.data;
		data.attendee['points'] = attendeePoints;
	} catch (error) {
		console.error(error);
	}
	if (data) {
		const attendee = data.attendee;
		dispatch(setAttendee(attendee));
		return data;
	} else {
		return error.message;
	}
};

export const fetchEventAttendee = (id, event_id) => async dispatch => {
	let { error, data } = await DirectoryServices.EventAttendee(id, event_id);
	try {
		const res = await DirectoryServices.EventAttendeeMetadata(id, event_id);
		const eventAttendeeMetadata = JSON.parse(res.data.eventAttendeeMetadata.metadata);
		data.eventAttendee['metadata'] = eventAttendeeMetadata;

		const pointRes = await DirectoryServices.EventAttendeePoints(id, event_id);
		const { attendeePoints } = pointRes.data;
		data.eventAttendee['points'] = attendeePoints;
	} catch (error) {
		console.error(error);
	}
	if (data) {
		const { eventAttendee } = data;
		dispatch(setEventAttendee(eventAttendee));
		return data;
	} else {
		return error.message;
	}
};

export const fetchAttendeePoints = (attendee_id, event_id) => async dispatch => {
	try {
		console.log('attendee_id', attendee_id);
		const { data } = await DirectoryServices.EventAttendeePoints(attendee_id, event_id);
		const { attendeePoints } = data;
		dispatch(setAttendeePoints(attendeePoints));
	} catch (error) {
		return error.message;
	}
};
