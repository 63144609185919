import rootReducer from '../redux/';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import { Enums } from '../services/AnalyticsService';

const logger = createLogger({
	collapsed: true,
	predicate: (getState, action) => {
		const { analytics, ready } = getState().analytics;
		if (ready) {
			analytics.event(Enums.search, {
				action: action.type,
				category: `Redux Action ${action.type}`,
			});
			console.log('Tracking Actions', action.type);
		}
		return process.env.NODE_ENV === 'development';
	},
});
// Middleware: Redux Persist Config

const middleware = [
	thunk,
	logger,
	// more middleware
];
// Redux: Store

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	/* preloadedState, */ composeEnhancers(
		applyMiddleware(...middleware),
		// const store = createStore(rootReducer, applyMiddleware(...middleware)
	),
);
// Middleware: Redux Persist Persister
let persistor = persistStore(store);
// Exports
export { store, persistor };
