import {
	SET_AGENDA,
	ADD_AGENDA_ITEM,
	REMOVE_AGENDA_ITEM,
	UPDATE_AGENDA_ITEM,
	ADD_MATERIALS,
	REMOVE_MATERIAL,
	UPDATE_MATERIAL,
	ADD_GROUPS_TO_MATERIAL,
	REMOVE_GROUPS_FROM_MATERIAL,
} from 'redux/actions';
import { arrToObj } from 'common';

const initialState = {
	agenda: {},
};

//TO DO: Make materials object as well
const agendaReducer = (state = initialState, action) => {
	const {
		type,
		agenda,
		agendaItem,
		agendaItemID,
		materials,
		material,
		materialID,
		groupIDs,
	} = action;
	const stateDup = { ...state };

	switch (type) {
		case SET_AGENDA:
			return { ...stateDup, agenda: arrToObj(agenda) };
		case ADD_AGENDA_ITEM:
			stateDup.agenda[agendaItem.id] = agendaItem;
			return { agenda: { ...stateDup.agenda } };
		case REMOVE_AGENDA_ITEM:
			delete stateDup.agenda[agendaItemID];
			return { agenda: { ...stateDup.agenda } };
		case UPDATE_AGENDA_ITEM:
			stateDup.agenda[agendaItem.id] = { ...stateDup.agenda[agendaItem.id], ...agendaItem };
			return { agenda: { ...stateDup.agenda } };
		case ADD_MATERIALS:
			stateDup.agenda[agendaItemID].materials = [
				...stateDup.agenda[agendaItemID].materials,
				...materials,
			];
			return { agenda: { ...stateDup.agenda } };
		case REMOVE_MATERIAL:
			stateDup.agenda[agendaItemID].materials = stateDup.agenda[agendaItemID].materials.filter(
				m => m.id !== materialID,
			);
			return { agenda: { ...stateDup.agenda } };
		case UPDATE_MATERIAL:
			stateDup.agenda[agendaItemID].materials = stateDup.agenda[agendaItemID].materials.map(m =>
				m.id !== material.id ? m : { ...m, ...material },
			);
			return { agenda: { ...stateDup.agenda } };
		case ADD_GROUPS_TO_MATERIAL:
			stateDup.agenda[agendaItemID].materials.forEach(m => {
				if (m.id === materialID) m.groups.push(...groupIDs);
			});
			return { agenda: { ...stateDup.agenda } };
		case REMOVE_GROUPS_FROM_MATERIAL:
			const groupIDsToRemove = groupIDs.map(g => g.id);
			stateDup.agenda[agendaItemID].materials.forEach(m => {
				if (m.id === materialID)
					m.groups = m.groups.filter(group => !groupIDsToRemove.includes(group.id));
			});
			return { agenda: { ...stateDup.agenda } };
		default:
			return state;
	}
};

export default agendaReducer;
