import { SET_EVENT_WIDGETS } from '../actions';
const preState = [];

export const widgetsReducer = (state = preState, action) => {
	const { type, widgets } = action;
	switch (type) {
		case SET_EVENT_WIDGETS:
			return [
				...widgets
					.filter(widget => widget.id !== 0)
					.map(widget => {
						const props = JSON.parse(widget.props || '{}');
						return { ...widget, props };
					}),
			];
		default:
			return state;
	}
};

export default widgetsReducer;
