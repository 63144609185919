import { UserService } from '../../services';
import { buildAuthHistory } from '../../common';

export const LOGOUT = 'LOGOUT';
export const LOGIN_WITH_SSO = 'LOGIN_WITH_SSO';
export const SET_PROFILE = 'SET_PROFILE';
export const LOGIN = 'LOGIN_SUCCESS';
export const PASSWORD_RESET_INITIATED = 'PASSWORD_RESET_INITIATED';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const ADD_GROUPS = 'ADD_GROUPS';
export const REMOVE_GROUPS = 'REMOVE_GROUPS';

//Setters
const setLogin = user => ({
	type: LOGIN,
	user,
});
const setSSOLogin = token => ({
	type: LOGIN_WITH_SSO,
	token,
});
export const setUser = profile => ({
	type: SET_PROFILE,
	profile,
});
const setResetPassword = () => ({
	type: PASSWORD_RESET,
});
const logoutUser = () => ({
	type: LOGOUT,
});
const requestPasswordReset = () => ({
	type: PASSWORD_RESET_INITIATED,
});

export const addGroups = (groupIDs, from) => ({
	type: ADD_GROUPS,
	from,
	groupIDs,
});

export const removeGroups = (groupIDs, from) => ({
	type: REMOVE_GROUPS,
	from,
	groupIDs,
});
//Getters
export const login = ({ email, password }) => async dispatch => {
	let { error, data } = await UserService.login(email, password);
	if (data) {
		const user = data.login;
		return dispatch(setLogin(user));
	} else {
		return error;
	}
};

export const loginWithSSO = token => async dispatch => {
	const loginMethod = 'SSO';
	createAuthHistory(loginMethod);
	return dispatch(setSSOLogin(token));
};

export const register = ({ attendee, event_id }) => async dispatch => {
	let { error, data } = await UserService.register(attendee, event_id);
	if (data) {
		const user = data.register;
		return dispatch(setLogin(user));
	} else {
		return error;
	}
};

export const fetchUser = token => async dispatch => {
	const response = await UserService.getUserData(token);
	const data = response.data || response;
	const profile = data && data.me ? { ...data.me } : {};
	return dispatch(setUser(profile));
};

export const logout = () => async dispatch => {
	return dispatch(logoutUser());
};

export const requestPasswordResetEmail = ({ email, reset_url }) => async dispatch => {
	let { error } = await UserService.requestPasswordResetEmail(email, reset_url);
	if (error) {
		return error;
	} else {
		return dispatch(requestPasswordReset());
	}
};

export const resetPassword = ({ password, token }) => async dispatch => {
	let { error, data, status } = await UserService.resetPassword(password, token);
	if (error) {
		return { error, data, status };
	} else {
		return dispatch(setResetPassword());
	}
};

export const createAuthHistory = async logInMethod => {
	const authHistory = buildAuthHistory(logInMethod);
	console.log('Authhistory', authHistory);
	await UserService.authHistory(authHistory);
};
