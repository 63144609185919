import { LibraryServices } from '../../services';

export const SET_LIBRARY = 'SET_LIBRARY';
export const CLEAR_SINGLE_MEDIA = 'CLEAR_SINGLE_MEDIA';
export const SET_SINGLE_MEDIA = 'SET_SINGLE_MEDIA';
export const UPDATE_LIBRARY = 'UPDATE_LIBRARY';
export const ADD_MEDIA = 'ADD_MEDIA';
export const REMOVE_MEDIA = 'REMOVE_MEDIA';
export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export const SET_LIBRARY_VIEW = 'SET_LIBRARY_VIEW';
export const UPDATE_CHILD_LIBRARY = 'UPDATE_CHILD_LIBRARY';
export const ADD_CHILD_LIBRARY = 'ADD_CHILD_LIBRARY';
export const DELETE_CHILD_LIBRARY = 'DELETE_CHILD_LIBRARY';
export const ADD_GROUPS_TO_LIBRARY = 'ADD_GROUPS_TO_LIBRARY';
export const REMOVE_GROUPS_FROM_LIBRARY = 'REMOVE_GROUPS_FROM_LIBRARY';

// Setters
const setLibrary = (library, from) => ({
	type: SET_LIBRARY,
	library,
	from,
});

export const setLibraryView = view => ({
	type: SET_LIBRARY_VIEW,
	view,
});

export const clearSingleMedia = () => ({
	type: CLEAR_SINGLE_MEDIA,
});
export const setSingleMedia = (selectedMedia, from) => ({
	type: SET_SINGLE_MEDIA,
	selectedMedia,
	from,
});
export const updateLibrary = library => ({
	type: UPDATE_LIBRARY,
	library,
});
export const addMedia = media => ({
	type: ADD_MEDIA,
	media,
});
export const updateMedia = media => ({
	type: UPDATE_MEDIA,
	media,
});
export const removeMedia = mediaID => ({
	type: REMOVE_MEDIA,
	mediaID,
});
export const addChildLibrary = library => ({
	type: ADD_CHILD_LIBRARY,
	library,
});
export const updateChildLibrary = library => ({
	type: UPDATE_CHILD_LIBRARY,
	library,
});
export const deleteChildLibrary = libraryID => ({
	type: DELETE_CHILD_LIBRARY,
	libraryID,
});
export const addGroupsToLibrary = (libraryID, groups) => ({
	type: ADD_GROUPS_TO_LIBRARY,
	libraryID,
	groups,
});
export const removeGroupsFromLibrary = (libraryID, groupIDs) => ({
	type: REMOVE_GROUPS_FROM_LIBRARY,
	libraryID,
	groupIDs,
});

// Getters
export const getEventLibrary = (libraryID, from) => async dispatch => {
	let { error, data } = await LibraryServices.libraryService(libraryID);
	if (data) {
		const { library } = data;
		dispatch(setLibrary(library, from));
	} else return error.message;
};

export const getAttendeeMaterials = (eventID, from) => async dispatch => {
	let { error, data } = await LibraryServices.attendeeMaterialsService(eventID);
	if (data) {
		const { attendeeMaterials } = data;
		dispatch(setLibrary(attendeeMaterials, from));
		return data;
	} else return error.message;
};

export const getSingleMedia = (mediaInfo, from) => async dispatch => {
	let { error, data } = await LibraryServices.mediaService(mediaInfo);
	if (data) {
		const { media } = data;
		dispatch(setSingleMedia(media, from));
	} else return error.message;
};
