import {
	DIRECTORIES,
	ATTENDEE,
	EVENT_ATTENDEE,
	SET_PROFILE,
	SET_ATTENDEE_POINTS,
	SET_EVENT,
} from 'redux/actions';

const initialState = {
	directory: {
		nodes: [],
		page_info: {
			has_next_page: false,
			total_count: 0,
		},
	},
	attendee: {},
};

const directoriesReducer = (state = initialState, action) => {
	const { type, directories, offset, attendee, eventAttendee, profile, points, event } = action;
	const stateDup = { ...state };
	switch (type) {
		case DIRECTORIES:
			const { nodes, page_info } = directories;
			return {
				...state,
				directory: { page_info, nodes: !offset ? nodes : [...stateDup.directory.nodes, ...nodes] },
			};
		case ATTENDEE:
			return { ...state, attendee };
		case EVENT_ATTENDEE:
			return { ...state, attendee: eventAttendee };
		case SET_PROFILE:
			if (state.attendee.id === profile.id)
				return { ...state, attendee: { ...state.attendee, ...profile } };
			return state;
		// TO DO: SET_EVENT should be set metadata. Separate from event object
		case SET_EVENT:
			if (event.updateMetadata) {
				const { attendee_metadata } = event;
				const metadata = JSON.parse(attendee_metadata.metadata);
				return { ...state, attendee: { ...state.attendee, metadata } };
			}
			return { ...state };
		case SET_ATTENDEE_POINTS:
			return { ...state, attendee: { ...state.attendee, points } };
		default:
			return state;
	}
};

export default directoriesReducer;
