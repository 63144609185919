import React, { memo, useEffect } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { getCorrectUrl, isJson } from '../../common';
import { useNavigate, useLocation } from 'react-router-dom';
import { Badge, Button } from 'antd';
import { CustomIcons } from './CustomIcons';

import { thunkFetchUnreadCounts } from 'redux/actions';

const Sidebar = memo(props => {
	const { getUnreadAnnouncements, event, user, onClose, unreadCount, collapsed } = props;
	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			if (event.id && user.id) {
				// move this to user avatar nav
				await getUnreadAnnouncements(user.id, event.id, 'useEffect - index/Sidebar');
			}
		})();
	}, [getUnreadAnnouncements, event.id, user.id]);

	const handleRoute = path => {
		if (path === pathname && onClose) onClose();
		else navigate(path);
	};

	const isAnonLogin = event.anonymous_login === 'TRUE';

	if (typeof isJson(event.theme) == 'object') {
		var { headerBg, headerText } = isJson(event.theme);
	}

	let siderValues;
	if (!event.side_navigation) {
		siderValues = {};
	} else siderValues = JSON.parse(event.side_navigation);

	let menus;
	if (siderValues.items) menus = siderValues.items;
	else if (siderValues.routes) {
		menus = siderValues.routes.filter(route => route.active).concat(siderValues.other);
	} else menus = [];

	const { unread_announcements, unread_app_notifications } = unreadCount;

	const menu = menus.map((menu, i) => {
		if (menu.type === 'messages' || menu.type === 'settings' || menu.type === 'notifications') {
			return null;
		}
		const queryParams = { token: user.token };
		const menuItem = (
			<div className="menuItem" key={i} style={{ color: headerText }}>
				{menu.icon ? <CustomIcons icon={menu.icon} style={{ color: headerText }} /> : ''}
				<span className="menuName">{menu.name}</span>
				{(menu.id === 'announcements' || menu.id === 'notifications') &&
					!isAnonLogin &&
					(collapsed ? (
						<Badge
							dot
							id="announcementBadge"
							count={menu.id === 'announcements' ? unread_announcements : unread_app_notifications}
						/>
					) : (
						<Badge
							id="announcementBadge"
							count={menu.id === 'announcements' ? unread_announcements : unread_app_notifications}
						/>
					))}
			</div>
		);
		return getCorrectUrl(menu, queryParams, menuItem);
	});
	return (
		<div
			className="menuContainer"
			style={{
				backgroundColor: headerBg,
			}}
		>
			<div className="StickTop">
				<div
					className="menuItem"
					onClick={() => handleRoute('/dashboard')}
					style={{ color: headerText }}
				>
					<CustomIcons icon="IoHome" style={{ color: headerText }} />
					<span className="menuName">Home</span>
				</div>
				{menu}
			</div>

			<div>
				<Button
					onClick={props.onCollapse}
					id="collapseButton"
					style={{ backgroundColor: headerBg }}
				>
					{props.collapsed ? (
						<div className="CollapseIcon">
							<CustomIcons icon="IoIosArrowDroprightCircle" style={{ color: headerText }} />
						</div>
					) : (
						<div className="CollapseIcon">
							<CustomIcons icon="IoIosArrowDropleftCircle" style={{ color: headerText }} />
						</div>
					)}
				</Button>
			</div>
		</div>
	);
});

const mapStateToProps = state => {
	return {
		user: state.user.user,
		event: state.events.event,
		unreadCount: state.unread,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getUnreadAnnouncements: (attendeeID, eventID, from) =>
			dispatch(thunkFetchUnreadCounts(attendeeID, eventID, from)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
