import React, { useCallback, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Enums } from 'services/AnalyticsService';
import { login } from 'redux/actions';

const WithAuth = props => {
	const { analytics: _analytics, event, path, user, children } = props;
	const { analytics, ready } = _analytics;

	const { pathname } = useLocation();

	const handlePageTracking = useCallback(async () => {
		if (ready) {
			analytics.event(Enums.pageView, {
				action: `Route ${path}-${event.id}`,
				value: `${path}-${event.id}`,
			});
			console.log(`Page track ${path}-${event.id}`);
		}
	}, [analytics, event.id, path, ready]);

	useEffect(() => {
		handlePageTracking();
	}, [handlePageTracking]);

	useEffect(() => {
		if (ready) {
			handlePageTracking();
		}
	}, [ready, handlePageTracking, pathname]);

	if (!user.isLoggedIn) {
		return <Navigate to="/login" replace />;
	} else {
		return children;
	}
};

const mapStateToProps = ({ analytics, events, user }) => ({
	analytics,
	event: events.event,
	user,
});

const mapDispatchToProps = dispatch => {
	return {
		login: (user, token) => dispatch(login(user, token)),
	};
};

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}

	return ComponentWithRouterProp;
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithAuth));
