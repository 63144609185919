export const types = { SET_ANALYTICS: 'SET_ANALYTICS' };

export const analyticsActions = {
	setAnalytics: (analytics, extra) => ({
		type: types.SET_ANALYTICS,
		analytics,
		ready: !!Object.keys(analytics),
		...(extra && { extra }),
	}),
};

const initialState = {
	analytics: {},
	ready: false,
};

export const reducer = (state = initialState, action) => {
	const { analytics, ready, type } = action;
	switch (type) {
		case types.SET_ANALYTICS:
			return { analytics, ready };
		default:
			return state;
	}
};
