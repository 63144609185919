import { NotificationsServices } from '../../services';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

const setNotifications = (notifications, from) => ({
	type: SET_NOTIFICATIONS,
	notifications,
	from,
});

export const addNotification = (notification, from) => ({
	type: ADD_NOTIFICATION,
	notification,
	from,
});

export const fetchNotifications = (attendeeID, eventID, from) => async dispatch => {
	try {
		const { data } = await NotificationsServices.attendeeNotifications(attendeeID, eventID);
		const { attendeeNotifications } = data;
		dispatch(setNotifications(attendeeNotifications, from));
	} catch (error) {
		console.log('n.actions.getNotifications', error);
		return error.message;
	}
};

export const setAllNotificationsAsRead = (attendeeID, eventID) => async () => {
	let { error } = await NotificationsServices.setAllNotificationsAsRead(attendeeID, eventID);
	if (error) console.log('Error setting notifications as read', error);
};
