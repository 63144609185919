/** @format */

import _AnalyticsService from './AnalyticsService';
import _AuditServices from './AuditServices';
import _UserService from './UserService';
import _EventsServices from './EventsServices';
import _LibraryServices from './LibraryServices';
import _FeedsServices from './FeedsServices';
import _AgendaServices from './AgendaServices';
import _DirectoryServices from './DirectoryServices';
import _ConnectionService from './ConnectionService';
import _CheckInService from './CheckInService';
import _AnnouncementsServices from './AnnouncementsServices';
import _KudosServices from './KudosServices';
import _DomainServices from './DomainServices';
import _NotificationPreferencesServices from './NotificationPreferencesServices';
import _NotificationsServices from './NotificationsServices';
import _WidgetServices from './WidgetServices';

export const AnalyticsService = _AnalyticsService;
export const AuditServices = _AuditServices;
export const UserService = _UserService;
export const EventsServices = _EventsServices;
export const LibraryServices = _LibraryServices;
export const FeedsServices = _FeedsServices;
export const AgendaServices = _AgendaServices;
export const DirectoryServices = _DirectoryServices;
export const ConnectionService = _ConnectionService;
export const CheckInService = _CheckInService;
export const AnnouncementsServices = _AnnouncementsServices;
export const KudosServices = _KudosServices;
export const DomainServices = _DomainServices;
export const NotificationPreferencesServices = _NotificationPreferencesServices;
export const NotificationsServices = _NotificationsServices;
export const WidgetServices = _WidgetServices;
