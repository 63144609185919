import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined, SettingOutlined } from '@ant-design/icons';
import Notifications from 'components/Notifications';
import NotificationPreferences from 'components/NotificationPreferences';
import UserInfo from './UserInfo';

import './UserNav.scss';

const UserNav = props => {
	const { user } = props;
	const navigate = useNavigate();

	const [section, setSection] = useState('activity');

	const title =
		section === 'settings' ? (
			<span>
				<LeftOutlined onClick={() => setSection('activity')} />
				<span>Notification Preferences</span>
			</span>
		) : (
			<>
				<UserInfo attendee={user} />
				<SettingOutlined onClick={() => setSection('settings')} />
			</>
		);

	const content =
		section === 'settings' ? <NotificationPreferences /> : <Notifications truncate={4} />;

	const footer =
		section === 'activity' ? (
			<div className="navLinks">
				<div onClick={() => navigate('/events')}>All Events</div>
				<div onClick={() => navigate('/logout')}>Logout</div>
			</div>
		) : null;

	return (
		<div className="UserNav">
			<div className="title">{title}</div>
			<div className="horizontal-divider" />
			<div className="content">{content}</div>
			{footer ? <div className="horizontal-divider" /> : null}
			{footer ? <div className="footer">{footer}</div> : null}
		</div>
	);
};

export default UserNav;
