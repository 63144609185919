import {
	SET_PROFILE,
	LOGOUT,
	LOGIN,
	LOGIN_WITH_SSO,
	PASSWORD_RESET_INITIATED,
	PASSWORD_RESET,
	ADD_GROUPS,
	REMOVE_GROUPS,
	UPDATE_GROUP,
	DELETE_GROUP,
} from 'redux/actions';

const initialState = {
	user: {},
	token: null,
	isLoggedIn: false,
	withSSO: false,
	passwordResetInitiated: false,
	passwordReset: false,
};

const userReducer = (state = initialState, action) => {
	const { type, user, profile, token, group, groupID, groupIDs } = action;
	switch (type) {
		case LOGOUT:
			return { ...state, isLoggedIn: false, token: null, user: {} };
		case LOGIN:
			return {
				...state,
				user: user,
				token: user.token,
				isLoggedIn: true,
			};
		case SET_PROFILE:
			const tkn = state.user.token || state.token || token;
			return { ...state, user: { ...state.user, ...profile, token: tkn } };
		case LOGIN_WITH_SSO:
			return {
				...state,
				user: { token },
				token: token,
				isLoggedIn: true,
				withSSO: true,
			};
		case PASSWORD_RESET_INITIATED:
			return { ...state, passwordResetInitiated: true };
		case PASSWORD_RESET:
			return { ...state, passwordReset: true };
		case ADD_GROUPS:
			return { ...state, user: { ...state.user, groups: [...state.user.groups, ...groupIDs] } };
		case REMOVE_GROUPS:
			const groupsToRemove = groupIDs.map(g => g.id);
			const updatedGroups = state.user.groups.filter(g => !groupsToRemove.includes(g.id));
			return { ...state, user: { ...state.user, groups: updatedGroups } };
		case UPDATE_GROUP:
			state.user.groups = state.user.groups.map(g => (g.id === group.id ? { ...g, ...group } : g));
			return { ...state };
		case DELETE_GROUP:
			state.user.groups = state.user.groups.filter(g => g.id !== groupID);
			return { ...state };
		default:
			return state;
	}
};

export default userReducer;
