import { ConnectionService } from '../services';

const types = {
	CONNECTIONS: 'CONNECTIONS',
	CONNECT: 'CONNECT',
};

export const ConnectionRedux = {
	connectionsAction: params => async dispatch => {
		let { error, data } = await ConnectionService.Connections(params);
		if (data) {
			dispatch({ type: types.CONNECTIONS, data: { connections: data } });
			return data;
		} else {
			return error.message;
		}
	},
	connectAction: params => async dispatch => {
		let { error, data } = await ConnectionService.Connect(params);
		console.log(error, data);
		if (data) {
			dispatch({ type: types.CONNECT, data: { connect: data } });
			return data;
		} else {
			return error.message;
		}
	},
};

const initialState = {
	connections: [],
	connect: [],
};

export const reducer = (state = initialState, action) => {
	const { type, data } = action;
	switch (type) {
		case types.CONNECTIONS:
			const { connections } = data;
			return { ...state, connections: connections };

		case types.CONNECT:
			const { connect } = data;
			return { ...state, connect: connect };
		default:
			return state;
	}
};
