import { SET_NOTIFICATIONS, ADD_NOTIFICATION } from 'redux/actions';

import { arrToObj } from 'common';

const initialState = {};
const notificationsReducer = (state = initialState, action) => {
	const stateDup = { ...state };
	const { type, notifications, notification } = action;
	switch (type) {
		case SET_NOTIFICATIONS:
			return arrToObj(notifications);
		case ADD_NOTIFICATION:
			stateDup[notification.id] = notification;
			return { ...stateDup };
		default:
			return state;
	}
};

export default notificationsReducer;
